import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  LatLng,
  MapLocation,
  PlaceAddress,
  PlaceIcon,
  PlaceLocation
} from 'shared/types';
import { placeIcons } from 'components/Map/Icons';
import SearchBar from 'components/Map/SearchBar';
import Map from 'components/Map';
import {
  LOCATION_ZOOM_LEVEL,
  VisuallyBusyMapLayers
} from 'components/Map/constants';
import useMapState, { IncidentMapStateUpdate } from 'state/useMapState';
import { getLatLngLocationName, getRandomId } from 'shared/utils';
import BlackButton from 'components/BlackButton';
import useMapPlaces from 'hooks/useMapPlaces';
import LocationMarker from 'components/Map/LocationMarker';
import './index.css';
import { DynamicMapCenter } from 'components/Map/DynamicMapCenter';

type PlaceFormProps = {
  initialPlace?: PlaceLocation;
  initialAddress?: PlaceAddress;
};

type FormValues = Omit<PlaceLocation, 'address' | 'id'> & {
  address: PlaceAddress | null;
};

const getDefaultMapCenter = (
  incidentMap: { center: { lat: number; lng: number } },
  placeAddress: PlaceAddress | null
): LatLng => {
  const lat = placeAddress?.coordinates.latitude ?? incidentMap.center.lat;
  const lng = placeAddress?.coordinates.longitude ?? incidentMap.center.lng;

  if (lat && lng) {
    return { lat, lng };
  }

  return Map.DefaultConfig.center;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 24,
    width: '100%',
    height: '100%'
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mapContainer: {
    width: '100%',
    height: '31vh',
    position: 'relative'
  },
  noTouchEvents: {
    pointerEvents: 'none'
  },
  mapHelperText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  selectInput: {
    height: 56,
    '& > div': {
      paddingTop: 14,
      paddingBottom: 14
    }
  },
  helperText: {
    margin: '3px 14px 0px'
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  icon: Yup.string().required('Required'),
  address: Yup.object({
    name: Yup.string(),
    coordinates: Yup.object({
      latitude: Yup.number(),
      longitude: Yup.number()
    }),
    bbox: Yup.array(Yup.number())
  })
    .required('Required')
    .nullable()
});

const PlaceForm = (props: PlaceFormProps): JSX.Element => {
  const { initialPlace, initialAddress } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { incidentMapState, updateIncidentMapState } = useMapState();
  const { addMapPlace, updateMapPlace, deleteMapPlace } = useMapPlaces();
  const history = useHistory();
  const [initialMapCenter] = useState(
    getDefaultMapCenter(incidentMapState, initialAddress ?? null)
  );

  const initialValues: FormValues = {
    name: initialPlace?.name || '',
    icon: initialPlace?.icon || 'home',
    address: initialPlace?.address || initialAddress || null
  };

  const { control, watch, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur'
  });

  const name = watch('name');
  const icon = watch('icon');
  const address = watch('address');

  const locationType =
    address?.type || initialPlace?.address?.type || initialAddress?.type;

  const handleAddPlace: SubmitHandler<FormValues> = (values) => {
    const addressLocation = {
      ...values.address,
      coordinates: {
        latitude: incidentMapState.center.lat,
        longitude: incidentMapState.center.lng
      }
    } as PlaceAddress;

    if (locationType === 'LatLngLocation') {
      addressLocation.name = getLatLngLocationName(
        incidentMapState.center.lat,
        incidentMapState.center.lng
      );
    }

    const placeLocation = {
      ...values,
      id: initialPlace?.id || getRandomId(),
      address: addressLocation
    } as PlaceLocation;

    if (initialPlace) {
      updateMapPlace(placeLocation);
      // @ts-expect-error 'goBack' is defined
      history.goBack();
      return;
    }

    addMapPlace(placeLocation);

    const mapLocation: MapLocation = {
      name: placeLocation.name,
      lat: placeLocation.address.coordinates.latitude,
      lng: placeLocation.address.coordinates.longitude,
      bbox: placeLocation.address.bbox,
      place: true
    };

    history.replace('/', mapLocation);
  };

  const handleDeleteMapPlace = async (placeId: number): Promise<void> => {
    // eslint-disable-next-line
    const deleteConfirmed = confirm(t('placeForm.deletePrompt'));

    if (!deleteConfirmed) return;

    deleteMapPlace(placeId);
    // @ts-expect-error 'goBack' is defined
    history.goBack();
  };

  const onViewportChange = useCallback(
    (viewport: IncidentMapStateUpdate) => {
      updateIncidentMapState(viewport);
    },
    [updateIncidentMapState]
  );

  const getInitialSearchTerm = (): string | undefined => {
    if (locationType === 'LatLngLocation') {
      return getLatLngLocationName(
        incidentMapState.center.lat,
        incidentMapState.center.lng
      );
    }

    return initialPlace?.address?.name || initialAddress?.name;
  };

  const initialName = getInitialSearchTerm();

  return (
    <Box sx={{ width: '100%', overflow: 'auto' }}>
      <Container maxWidth="sm" className={classes.container}>
        <Box className={classes.contentContainer}>
          <Box
            className={cx(
              classes.mapContainer,
              !address && classes.noTouchEvents
            )}
            data-testid="place-form-map"
          >
            <Map
              center={initialMapCenter}
              disableMapLayers={VisuallyBusyMapLayers}
              noControls
              withPlaces={false}
              cursor={address ? 'auto' : 'not-allowed'}
              interactive={!!address}
            >
              <DynamicMapCenter
                lat={address?.coordinates.latitude}
                lng={address?.coordinates.longitude}
                zoom={LOCATION_ZOOM_LEVEL}
              />
              <Map.MapEvents onViewportChange={onViewportChange} />
            </Map>
            {!!address && (
              <LocationMarker
                name={name || initialName || address.name}
                placeIcon={icon}
              />
            )}
          </Box>

          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.mapHelperText}
          >
            {t('placeForm.mapHelperText')}
          </Typography>

          <form onSubmit={handleSubmit(handleAddPlace)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const hasError = !!fieldState.error;
                    return (
                      <>
                        <SearchBar
                          formField
                          label={t('placeForm.inputs.address.label')}
                          name={field.name}
                          error={hasError}
                          onBlur={field.onBlur}
                          onLocationChange={(location): void | undefined => {
                            if (!location) {
                              field.onChange(null);
                              return;
                            }
                            field.onChange({
                              name: location.name,
                              formattedAddress: location.formattedAddress,
                              coordinates: {
                                latitude: location.lat,
                                longitude: location.lng
                              },
                              bbox: location.bbox,
                              type: location.type
                            } as PlaceAddress);
                          }}
                          initialSearchTerm={initialName}
                          ref={field.ref}
                        />
                        {hasError && (
                          <FormHelperText error className={classes.helperText}>
                            {fieldState.error?.message}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-name"
                        label={t('placeForm.inputs.name.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error?.message ??
                          t('placeForm.inputs.name.helperText')
                        }
                        required
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="icon"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        select
                        id="field-control-icon"
                        label={t('placeForm.inputs.icon.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required
                        sx={{ '.MuiSelect-select': { display: 'flex' } }}
                      >
                        {(Object.keys(placeIcons) as PlaceIcon[]).map(
                          (placeIcon: PlaceIcon) => {
                            const Icon = placeIcons[placeIcon];
                            return (
                              <MenuItem key={placeIcon} value={placeIcon}>
                                <img
                                  src={Icon.data}
                                  alt={Icon.name}
                                  data-testid={`${placeIcon}-icon`}
                                  style={{ filter: 'invert(100%)' }}
                                />
                              </MenuItem>
                            );
                          }
                        )}
                      </TextField>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  <b>{t('addPlace.privatePlaces')}</b>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth className={classes.button} type="submit">
                  {t('common.save')}
                </Button>
              </Grid>

              {!!initialPlace && (
                <Grid item xs={12}>
                  <BlackButton
                    fullWidth
                    onClick={() => handleDeleteMapPlace(initialPlace.id)}
                  >
                    {t('common.delete')}
                  </BlackButton>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default PlaceForm;
