import { useMemo, useEffect, useState, ChangeEvent } from 'react';
import {
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { debounce, noop } from 'lodash-es';
import GeoEventReportsMap from 'components/GeoEventReportsMap';
import {
  GeoEvent,
  LatLng,
  NotificationType,
  Report,
  ReportMedia
} from 'shared/types';
import { API } from 'api';
import { useMapState, useSnackbarState } from 'state';
import { Capacitor } from '@capacitor/core';
import {
  isValidLat,
  isValidLng,
  parseNumericString,
  removeHTMLTags
} from 'shared/utils';
import { assetOptions } from 'pages/AddIncidentReport/ReportForm/constants';
import Map from 'components/Map';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import Slider from 'components/Slider';
import { isSupportedEmbedUrl } from 'shared/embedHelpers';
import RichTextEditor from 'components/RichTextEditor';
import { validateReport } from 'pages/AddIncidentReport/ReportForm/utils';
import { DynamicMapCenter } from 'components/Map/DynamicMapCenter';
import { DEFAULT_LAT, DEFAULT_LON } from '../../../constants';
import ImagePreview from './ImagePreview';

type EditReportFormProps = {
  geoEvent: GeoEvent;
  report: Report;
};

type FormValues = {
  geoEventId: number;
  message: string;
  asset: 'image' | 'socialEmbed' | null;
  mediaUrl: string;
  lat: string;
  lng: string;
  az: number;
  embedUrl: string;
};

type ReportUpdateData = {
  message: string;
  embedUrl?: string;
  media?: Omit<ReportMedia, 'dateCreated' | 'dateModified'>[];
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updateReport = (reportId: number, data: ReportUpdateData) =>
  API.patch(`reports/${reportId}`, data);

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const deleteMedia = (mediaId: number) => API.delete(`media/${mediaId}`);

const getDefaultMapCenter = (incidentMap: {
  center: { lat: number; lng: number };
}): LatLng => {
  const { lat } = incidentMap.center;
  const { lng } = incidentMap.center;

  if (lat && lng) {
    return { lat, lng };
  }

  return Map.DefaultConfig.center;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  formContainer: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: 720
  },
  form: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)'
  },
  itemLabel: {
    textTransform: 'capitalize'
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    height: 48
  },
  mapContainer: {
    width: '100%',
    height: 225,
    borderRadius: theme.shape.borderRadius * 1.34,
    overflow: 'hidden'
  }
}));

const EditReportForm = (props: EditReportFormProps): JSX.Element => {
  const { geoEvent, report } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up(768), {
    defaultMatches: !Capacitor.isNativePlatform()
  });
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const { incidentMapState } = useMapState();
  const [initialMapCenter] = useState(getDefaultMapCenter(incidentMapState));
  const [latitude, setLatitude] = useState(
    report.media?.[0]?.lat || DEFAULT_LAT
  );
  const [longitude, setLongitude] = useState(
    report.media?.[0]?.lng || DEFAULT_LON
  );
  const queryClient = useQueryClient();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          geoEventId: Yup.number().required(
            t('editReport.inputs.incident.required')
          ),
          message: Yup.string()
            .required(t('editReport.inputs.message.required'))
            .min(3, t('editReport.inputs.message.minError')),
          mediaUrl: Yup.string(),
          lat: Yup.number()
            .typeError(t('addIncidentReport.inputs.latitude.typeError'))
            .min(-90, t('addIncidentReport.inputs.latitude.minError'))
            .max(90, t('addIncidentReport.inputs.latitude.maxError'))
            .when('lng', {
              is: (lng?: string) => !!lng,
              then: Yup.number()
                .transform(parseNumericString)
                .nullable()
                .required(t('addIncidentReport.inputs.latitude.required')),
              otherwise: Yup.number().transform(parseNumericString).nullable()
            }),
          lng: Yup.number()
            .typeError(t('addIncidentReport.inputs.longitude.typeError'))
            .min(-180, t('addIncidentReport.inputs.longitude.minError'))
            .max(180, t('addIncidentReport.inputs.longitude.maxError'))
            .when('lat', {
              is: (lat?: string) => !!lat,
              then: Yup.number()
                .transform(parseNumericString)
                .nullable()
                .required(t('addIncidentReport.inputs.longitude.required')),
              otherwise: Yup.number().transform(parseNumericString).nullable()
            }),
          az: Yup.number()
            .typeError(t('addIncidentReport.inputs.azimuth.typeError'))
            .min(0, t('addIncidentReport.inputs.azimuth.minError'))
            .max(360, t('addIncidentReport.inputs.azimuth.maxError')),
          embedUrl: Yup.string()
            .url(t('addIncidentReport.inputs.embedUrl.invalidError'))
            .test(
              'is-supported-embed-url',
              t('addIncidentReport.inputs.embedUrl.unsupportedError'),
              (value) => (value ? isSupportedEmbedUrl(value) : true)
            )
        },
        [['lat', 'lng']]
      ),
    [t]
  );

  const initialValues: FormValues = useMemo(
    () => ({
      geoEventId: geoEvent.id,
      message: report.message,
      // eslint-disable-next-line no-nested-ternary
      asset: report.media?.[0]
        ? 'image'
        : report.embedUrl
        ? 'socialEmbed'
        : null,
      mediaUrl: report.media?.[0]?.url || '',
      lat: report.media?.[0]?.lat?.toString() || '',
      lng: report.media?.[0]?.lng?.toString() || '',
      az: report.media?.[0]?.az || 0,
      embedUrl: report.embedUrl || ''
    }),
    [geoEvent.id, report.embedUrl, report.media, report.message]
  );

  const { control, handleSubmit, reset, watch, setValue, formState } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
      mode: 'onBlur'
    });

  useEffect(() => {
    // Initial values changed, reset the form to reflect these changes.
    reset(initialValues);
    setLatitude(parseFloat(initialValues.lat));
    setLongitude(parseFloat(initialValues.lng));
  }, [reset, initialValues]);

  const goBack = (): void => {
    history.push(`/i/${geoEvent.id}`);
  };

  const patchReportMutation = useMutation({
    mutationFn: (data: ReportUpdateData) => updateReport(report.id, data)
  });

  const deleteMediaMutation = useMutation({
    mutationFn: () => deleteMedia(report.media[0].id)
  });

  const handleSubmitReport: SubmitHandler<FormValues> = async (values) => {
    try {
      const updateData: ReportUpdateData = { message: values.message };

      if (report.embedUrl) {
        updateData.embedUrl = values.embedUrl;
      }

      if (report.media?.[0]?.id) {
        if (values.mediaUrl) {
          updateData.media = [
            {
              ...report.media[0],
              lat: parseFloat(values.lat),
              lng: parseFloat(values.lng),
              az: values.az
            }
          ];
        } else if (!values.mediaUrl) {
          updateData.media = [];
          await deleteMediaMutation.mutateAsync();
        }
      }

      await patchReportMutation.mutateAsync(updateData);
      queryClient.invalidateQueries({
        queryKey: ['report', report.id]
      });
      setSnackbar(t('editReport.successMessage'), 'success');
      goBack();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorStatus = error?.response?.status;
      if (errorStatus === 403) {
        setSnackbar(
          'Error 403: You cannot submit or update reports for this incident.',
          'error'
        );
      } else
        setSnackbar(error.message || t('common.unknownErrorTryAgain'), 'error');
    }
  };

  const updateLatitude = useMemo(() => debounce(setLatitude, 500), []);
  const updateLongitude = useMemo(() => debounce(setLongitude, 500), []);

  const handleCoordsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Allow users to paste `lat, lng` as a string and lets parse it for them
    const val = e.target.value;
    const name = e.target.name as 'lat' | 'lng';

    const latLng = val.split(/, ?/);

    if (latLng.length === 2) {
      // User pasted string values
      const [lat, lng] = latLng;

      setValue('lat', lat);
      setValue('lng', lng);

      // We don't need to debounce state update
      setLatitude(parseNumericString(lat));
      setLongitude(parseNumericString(lng));
      return;
    }

    setValue(name, val);

    if (name === 'lat') updateLatitude(parseNumericString(val));
    if (name === 'lng') updateLongitude(parseNumericString(val));
  };

  const handleDeleteImage = (): void => {
    const msg = t('editReport.deleteImageConfirmation');
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm(msg)) return;

    setValue('asset', null);
    setValue('mediaUrl', '');
    setValue('lat', '');
    setValue('lng', '');
    setValue('az', 0);
    setLatitude(DEFAULT_LAT);
    setLongitude(DEFAULT_LON);
  };

  const [mediaUrl, az] = watch(['mediaUrl', 'az']);

  const mapLat = isValidLat(latitude) ? latitude : null;
  const mapLng = isValidLng(longitude) ? longitude : null;

  const disabled = patchReportMutation.isPending;

  return (
    <Box
      className={classes.root}
      sx={{
        flexDirection: isLargeMediaQuery ? 'row' : 'column',
        overflow: isLargeMediaQuery ? 'hidden' : 'auto'
      }}
    >
      <Box
        sx={{
          flex: 1,
          minHeight: isLargeMediaQuery ? '100%' : 314,
          display: 'flex'
        }}
      >
        <GeoEventReportsMap
          geoEvent={geoEvent}
          hiddenMapPixels={null}
          noControls
        />
      </Box>
      <Container
        maxWidth={false}
        className={isLargeMediaQuery ? classes.formContainer : undefined}
      >
        <form
          onSubmit={handleSubmit(handleSubmitReport)}
          noValidate
          className={classes.form}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Box sx={{ paddingTop: 1 }}>
                <Typography variant="h3">
                  {t('editReport.sections.incident')}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Controller
                name="geoEventId"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  const { ref, ...muiFieldProps } = field;
                  return (
                    <TextField
                      select
                      id="field-control-incident"
                      label={t('editReport.inputs.incident.label')}
                      fullWidth
                      {...muiFieldProps}
                      inputRef={ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      required
                      className={classes.itemLabel}
                      disabled
                    >
                      <MenuItem
                        value={geoEvent.id}
                        className={classes.itemLabel}
                      >
                        {geoEvent.name}
                      </MenuItem>
                    </TextField>
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Box sx={{ paddingTop: 1 }}>
                <Typography variant="h3">
                  {t('editReport.sections.update')}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Controller
                name="message"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  const reportMessageWarning = validateReport({
                    t,
                    report: removeHTMLTags(field.value),
                    fpsChecked: geoEvent.data.isFps,
                    isSilent:
                      geoEvent.notificationType === NotificationType.Silent
                  });
                  return (
                    <>
                      <RichTextEditor
                        id="field-control-message"
                        key={initialValues.message}
                        label={t('editReport.inputs.message.label')}
                        initialValue={initialValues.message}
                        onChange={field.onChange}
                        editable={!formState.isSubmitting}
                        error={!!fieldState.error}
                        required
                        warning={!!reportMessageWarning}
                      />
                      {!!(fieldState.error || reportMessageWarning) && (
                        <FormHelperText
                          error={!!fieldState.error}
                          sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                        >
                          {fieldState.error?.message || reportMessageWarning}
                        </FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            </Grid>

            {(report.media?.[0] || report.embedUrl) && (
              <>
                <Grid item>
                  <Typography variant="h3">
                    {t('addIncidentReport.sections.media')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Controller
                    name="asset"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, onChange, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-asset"
                          label={t('addIncidentReport.inputs.asset.label')}
                          fullWidth
                          {...muiFieldProps}
                          value={field.value ?? 'placeholder'}
                          onChange={(e) =>
                            onChange(
                              e.target.value === 'placeholder'
                                ? null
                                : e.target.value
                            )
                          }
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled
                        >
                          <MenuItem value="placeholder">
                            {t('addIncidentReport.inputs.asset.options.type')}
                          </MenuItem>
                          {assetOptions.map(({ transLabelKey, value }) => {
                            return (
                              <MenuItem
                                key={transLabelKey}
                                value={value as string}
                              >
                                {t(transLabelKey)}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      );
                    }}
                  />
                </Grid>
              </>
            )}

            {report.embedUrl && (
              <Grid item>
                <Controller
                  name="embedUrl"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-embedUrl"
                        label={t('addIncidentReport.inputs.embedUrl.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error?.message ||
                          t('addIncidentReport.inputs.embedUrl.helperText')
                        }
                        disabled={formState.isSubmitting}
                      />
                    );
                  }}
                />
              </Grid>
            )}

            {mediaUrl && (
              <Grid item>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="mediaUrl"
                      control={control}
                      render={({ field }): JSX.Element => {
                        return (
                          <ImagePreview
                            url={`${import.meta.env.VITE_BASE_MEDIA_URL}${
                              field.value
                            }`}
                            onDelete={handleDeleteImage}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name="lat"
                          control={control}
                          render={({ field, fieldState }): JSX.Element => {
                            const { ref, ...muiFieldProps } = field;
                            return (
                              <TextField
                                id="field-control-lat"
                                label={t(
                                  'addIncidentReport.inputs.latitude.label'
                                )}
                                fullWidth
                                {...muiFieldProps}
                                inputRef={ref}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                placeholder="00.000000"
                                onChange={handleCoordsChange}
                                inputProps={{
                                  pattern: '[0-9]+([.,][0-9]+)?'
                                }}
                                disabled={formState.isSubmitting}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          name="lng"
                          control={control}
                          render={({ field, fieldState }): JSX.Element => {
                            const { ref, ...muiFieldProps } = field;
                            return (
                              <TextField
                                id="field-control-lng"
                                label={t(
                                  'addIncidentReport.inputs.longitude.label'
                                )}
                                fullWidth
                                {...muiFieldProps}
                                inputRef={ref}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                placeholder="00.000000"
                                onChange={handleCoordsChange}
                                inputProps={{
                                  pattern: '[0-9]+([.,][0-9]+)?'
                                }}
                                disabled={formState.isSubmitting}
                              />
                            );
                          }}
                        />
                      </Grid>

                      {!!latitude && !!longitude && (
                        <>
                          <Grid item xs={12}>
                            <Controller
                              name="az"
                              control={control}
                              render={({ field, fieldState }): JSX.Element => {
                                const { ref, onChange, ...muiFieldProps } =
                                  field;
                                return (
                                  <TextField
                                    id="field-control-az"
                                    label={t(
                                      'addIncidentReport.inputs.azimuth.label'
                                    )}
                                    fullWidth
                                    {...muiFieldProps}
                                    onChange={(e) =>
                                      onChange(Number(e.target.value))
                                    }
                                    inputRef={ref}
                                    error={!!fieldState.error}
                                    type="number"
                                    inputProps={{
                                      pattern: '[0-9]+([.,][0-9]+)?',
                                      inputMode: 'numeric',
                                      onWheel: (e) => e.currentTarget.blur()
                                    }}
                                    disabled={formState.isSubmitting}
                                  />
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Controller
                              name="az"
                              control={control}
                              render={({ field, fieldState }): JSX.Element => {
                                return (
                                  <Box sx={{ width: '100%' }}>
                                    <FormControl fullWidth>
                                      <Slider
                                        value={field.value}
                                        onChange={(
                                          _,
                                          newValue: number | number[]
                                        ): void => {
                                          const azValue =
                                            typeof newValue === 'number'
                                              ? newValue
                                              : newValue[0];
                                          field.onChange(azValue);
                                        }}
                                        max={360}
                                        min={0}
                                        disabled={formState.isSubmitting}
                                      />
                                      {!!fieldState.error?.message && (
                                        <FormHelperText error>
                                          {fieldState.error.message}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Box>
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Box className={classes.mapContainer}>
                              <Map
                                id="media-location"
                                center={initialMapCenter}
                                disableMapLayers={VisuallyBusyMapLayers}
                                noControls
                                withPlaces={false}
                              >
                                <DynamicMapCenter lat={mapLat} lng={mapLng} />

                                <Map.WildFireGeoEventMarker
                                  geoEvent={geoEvent}
                                />

                                {mapLat && mapLng && (
                                  <Map.ReportMarker
                                    onClick={noop}
                                    isFadable={false}
                                    lat={mapLat}
                                    lng={mapLng}
                                    az={az}
                                    overrideCoords
                                  />
                                )}
                              </Map>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item>
              <Box sx={{ paddingTop: 3 }}>
                <Typography variant="h3">
                  {t('editReport.sections.preview')}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Typography align="center">
                {t('editReport.silentNotification')}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                fullWidth
                className={classes.button}
                disabled={disabled}
              >
                {patchReportMutation.isPending ? (
                  <CircularProgress size={24} />
                ) : (
                  t('editReport.buttons.save')
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default EditReportForm;
