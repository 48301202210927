import { CacheAPI } from 'api';
import { CameraTimeLapseFrameDetails } from './useAlertCameraPlayerState.types';

export const fetchCameraTimeLapse = async (
  cameraId: string,
  replayTime: string
): Promise<CameraTimeLapseFrameDetails[]> => {
  const response = await CacheAPI.get<CameraTimeLapseFrameDetails[]>(
    `/cameras/timelapse/${cameraId}`,
    { params: { duration: replayTime } }
  );
  return response.data;
};

export const preloadImages = (urls: string[]): void => {
  if (!urls.length) return;
  for (const url of urls) {
    const image = new Image();
    image.src = url;
  }
};
