import { API } from '../../api';
import {
  DeepPartial,
  GeoEvent,
  GeoEventCreateUpdateData
} from '../../shared/types';

type PostGeoEventProps = {
  geoEventData: GeoEventCreateUpdateData;
  skipDuplicationCheck?: boolean;
};

type PatchGeoEventProps = {
  id: number;
  geoEventData: DeepPartial<GeoEventCreateUpdateData>;
};

export const fetchGeoEvent = async (geoEventId: number): Promise<GeoEvent> => {
  const response = await API.get(`geo_events/${geoEventId}`);
  return response.data;
};

export const postGeoEvent = async (
  props: PostGeoEventProps
): Promise<GeoEventCreateUpdateData> => {
  const { geoEventData, skipDuplicationCheck } = props;
  const response = await API.post(`geo_events/`, geoEventData, {
    params: {
      skipDuplicationCheck
    }
  });

  return response.data;
};

export const patchGeoEvent = async (
  props: PatchGeoEventProps
): Promise<GeoEventCreateUpdateData> => {
  const { id, geoEventData } = props;
  const response = await API.patch(`geo_events/${id}`, geoEventData);
  return response.data;
};
