import { Drawer, List, Menu, PopoverPosition } from '@mui/material';

import { LatLng } from 'shared/types';
import { Fragment, useMemo } from 'react';
import CopyPositionMenuItem from './CopyPositionMenuItem';
import DropPinMenuItem from './DropPinMenuItem';
import MeasureDistanceMenuItem from './MeasureDistanceMenuItem';
import { MapToolsMenuItemProps } from './types';

type ContextMenuItemKey = 'copyPosition' | 'dropPin' | 'measureDistance';

type MapToolsContextMenuProps =
  | {
      variant: 'menu';
      onClose: () => void;
      pointCoordinates: LatLng | null;
      exclude?: ContextMenuItemKey[];
      anchorPosition: PopoverPosition;
    }
  | {
      variant: 'drawer';
      onClose: () => void;
      pointCoordinates: LatLng | null;
      exclude?: ContextMenuItemKey[];
    };

type ContextMenuItemType = {
  key: ContextMenuItemKey;
  getContent: (props: MapToolsMenuItemProps) => JSX.Element;
};

const MENU_ITEMS: ContextMenuItemType[] = [
  {
    key: 'copyPosition',
    getContent: (props) => <CopyPositionMenuItem {...props} />
  },
  {
    key: 'dropPin',
    getContent: (props) => <DropPinMenuItem {...props} />
  },
  {
    key: 'measureDistance',
    getContent: (props) => <MeasureDistanceMenuItem {...props} />
  }
];

const MapToolsContextMenu = (
  props: MapToolsContextMenuProps
): JSX.Element | null => {
  const { pointCoordinates, onClose, variant, exclude = [] } = props;

  const filteredItems = useMemo(() => {
    return MENU_ITEMS.filter((item) => !exclude.includes(item.key));
  }, [exclude]);

  if (!pointCoordinates) {
    return null;
  }

  if (variant === 'drawer') {
    return (
      <Drawer
        anchor="bottom"
        open={pointCoordinates !== null}
        onClose={onClose}
      >
        <List>
          {filteredItems.map((item) => (
            <Fragment key={item.key}>
              {item.getContent({
                position: pointCoordinates,
                onClick: onClose,
                padding: 2
              })}
            </Fragment>
          ))}
        </List>
      </Drawer>
    );
  }
  const { anchorPosition } = props;
  return (
    <Menu
      open={pointCoordinates !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
    >
      {filteredItems.map((item) => (
        <Fragment key={item.key}>
          {item.getContent({
            position: pointCoordinates,
            onClick: onClose,
            padding: 1
          })}
        </Fragment>
      ))}
    </Menu>
  );
};

export default MapToolsContextMenu;
