import {
  AutocompleteProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getGeoEventIcon } from 'components/Map/Icons';
import { getHighlightedSearchMatch } from '../useMapSearch';
import { fetchGeoEventsForAutocomplete } from './useWildfireAutocomplete.queries';
import { RelatedGeoEvent } from '../../shared/types';

export type UseWildfireAutocomplete = {
  muiAutocompleteProps: Omit<
    AutocompleteProps<RelatedGeoEvent, true, false, false>,
    'renderInput'
  >;
};

export type UseWildfireAutocompleteProps = {
  enabled: boolean;
  value: RelatedGeoEvent[];
  filterGeoEvents?: (geoEvents: RelatedGeoEvent[]) => RelatedGeoEvent[];
};

const renderOption = (
  optionProps: object,
  option: RelatedGeoEvent,
  { inputValue }: { inputValue: string }
): JSX.Element => {
  const icon = getGeoEventIcon(option);

  return (
    <ListItem {...optionProps}>
      <ListItemIcon sx={{ minWidth: 36 }}>
        <img src={icon.data} alt="" width={24} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography>
            {getHighlightedSearchMatch(inputValue, option.name)}
          </Typography>
        }
        secondary={option.address}
      />
    </ListItem>
  );
};

export const useWildfireAutocomplete = (
  props: UseWildfireAutocompleteProps
): UseWildfireAutocomplete => {
  const { enabled, filterGeoEvents, value } = props;
  const { data: geoEvents, isFetching } = useQuery({
    queryKey: ['search_geo_events'],
    queryFn: async () => {
      const geoEventResults = await fetchGeoEventsForAutocomplete();

      if (filterGeoEvents) {
        return filterGeoEvents(geoEventResults);
      }

      return geoEventResults;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled
  });

  const selectedID = value.map((geoEvent) => geoEvent.id);

  const geoEventOptionsWithoutSelected =
    geoEvents?.filter((ge) => !selectedID.includes(ge.id)) || [];

  return {
    muiAutocompleteProps: {
      multiple: true,
      options: geoEventOptionsWithoutSelected,
      getOptionLabel: (geoEvent: RelatedGeoEvent) => geoEvent.name,
      isOptionEqualToValue: (option: RelatedGeoEvent, val: RelatedGeoEvent) =>
        option.id === val.id,
      loading: isFetching,
      renderOption
    }
  };
};
