import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API, CacheAPI } from 'api';
import { EvacZone, GeoEvent } from 'shared/types';
import { useCacheState } from '../state';

type UseGeoEventEvacZones = {
  geoEventEvacZones: EvacZone[];
};

const fetchEvacZonesForRegions = (
  regionIds: number[],
  cacheBusterTs: number | null
): Promise<AxiosResponse<EvacZone[]>> => {
  const baseParams = { regionIds: regionIds.toString() };
  if (cacheBusterTs) {
    return CacheAPI.get<EvacZone[]>('evac_zones/', {
      params: { ...baseParams, ts: cacheBusterTs }
    });
  }

  return API.get<EvacZone[]>('evac_zones/', { params: baseParams });
};

export const useGeoEventEvacZones = (
  geoEvent?: GeoEvent
): UseGeoEventEvacZones => {
  const { cacheBusterTs } = useCacheState();
  const regionIds =
    (geoEvent && geoEvent.regions.map((region) => region.id)) || [];
  const enabled = Boolean(geoEvent && regionIds.length);

  const query = useQuery({
    queryKey: ['evacuation-zones', geoEvent?.id, cacheBusterTs],
    queryFn: () => fetchEvacZonesForRegions(regionIds, cacheBusterTs),
    enabled,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 6 // 6 minutes - must be bigger than staleTime
  });

  const geoEventEvacZones = useMemo(
    () => (query.data?.data ?? []) as EvacZone[],
    [query.data?.data]
  );

  return { geoEventEvacZones };
};
