import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GeoEvent } from 'shared/types';

type UseMapGeoEventProps = {
  geoEvent: GeoEvent;
};

/**
 * Provides a copy of the geo event, updated with active form values
 */
export const useFormGeoEvent = (props: UseMapGeoEventProps): GeoEvent => {
  const { geoEvent } = props;
  const { control } = useFormContext();

  const containment = useWatch({
    control,
    name: 'containment',
    defaultValue: null
  });
  const acreage = useWatch({ control, name: 'acreage', defaultValue: null });
  const isFps = useWatch({ control, name: 'isFps', defaultValue: false });
  const status = useWatch({ control, name: 'status', defaultValue: 'active' });

  const mapGeoEvent = useMemo(
    () => ({
      ...geoEvent,
      isActive: status === 'active',
      data: {
        ...geoEvent.data,
        acreage,
        containment,
        isFps
      }
    }),
    [geoEvent, status, acreage, containment, isFps]
  );

  return mapGeoEvent;
};
