import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, List, ListSubheader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'state';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import useMapLayersState from 'state/useMapLayersState';
import { usePoisState } from 'state/usePoisState';
import useWebGL2State from 'state/useWebGL2State';
import { makeStyles } from 'tss-react/mui';
import { MEMBERSHIP_PLANS } from '../../constants';
import { LayerGroupControlItem } from './controls/LayerGroupControlItem';
import {
  LAYER_GROUPS,
  MapLayer,
  MapLayerDetail,
  MapLayersDetails
} from './constants';
import { layerNameToEntityType, layerNameToPoiType } from './utils';

const useStyles = makeStyles()(() => ({
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1
  }
}));

export const DrawerGroupContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    groupDrawerContent,
    setGroupDrawerOpen,
    mapLayers,
    setMapLayerVisibility,
    disabledLayers
  } = useMapLayersState();
  const { hasWebGL2 } = useWebGL2State();
  const history = useHistory();
  const { selectedPoi, clearSelectedPois } = usePoisState();
  const { mapDrawerEntity, setMapDrawerEntity } = useMapDrawerEntityState();
  const { showMembershipFeatures, showMembershipProFeatures } = useAuthState();

  const handleChangeLayer = (
    mapLayer: string,
    details: MapLayerDetail,
    enabled: boolean
  ): void => {
    if (details.minMembership === 'regular' && !showMembershipFeatures) {
      history.push(`/support_us/${MEMBERSHIP_PLANS.yearly}`);
      return;
    }
    if (details.minMembership === 'pro' && !showMembershipProFeatures) {
      history.push(`/support_us/${MEMBERSHIP_PLANS.proYearly}`);
      return;
    }
    const baseName = enabled ? 'layer_toggle_on' : 'layer_toggle_off';
    const eventName = `${baseName}_${mapLayer}`;
    FirebaseAnalytics.logEvent({
      name: eventName,
      params: {}
    });
    FirebaseAnalytics.logEvent({
      name: baseName,
      params: { mapLayer }
    });
    setMapLayerVisibility(mapLayer, enabled);
    if (!enabled) {
      if (layerNameToPoiType[mapLayer] === selectedPoi?.type) {
        clearSelectedPois();
      }
      if (layerNameToEntityType[mapLayer] === mapDrawerEntity?.type) {
        setMapDrawerEntity(null);
      }
    }
  };

  const renderControlItem = (mapLayer: MapLayer): JSX.Element | null => {
    const layerDetails: MapLayerDetail = MapLayersDetails[mapLayer];

    if (!layerDetails.imageSrc) return null;

    const disabled =
      (layerDetails.requiresWebGL2 && !hasWebGL2) ||
      disabledLayers.includes(mapLayer);

    return (
      <LayerGroupControlItem
        key={mapLayer}
        imageSrc={layerDetails.imageSrc}
        titleKey={layerDetails.titleKey}
        subtitleKey={layerDetails.subtitleKey}
        disabled={disabled}
        checked={!disabled && mapLayers.includes(mapLayer)}
        onChange={(enabled): void =>
          handleChangeLayer(mapLayer, layerDetails, enabled)
        }
        mapLayer={mapLayer}
        internalOnly={layerDetails.internalOnly}
        minMembership={layerDetails.minMembership}
      />
    );
  };

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          sx={{ lineHeight: 1.2, padding: 2, paddingBottom: 0 }}
        >
          <Typography variant="h3" color="textPrimary" fontWeight="bold">
            {t(`map.layers.general.${groupDrawerContent}.title`)}
          </Typography>
          <IconButton
            aria-label={t('common.close')}
            className={classes.closeButton}
            onClick={() => setGroupDrawerOpen(false)}
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </ListSubheader>
      }
    >
      {LAYER_GROUPS[groupDrawerContent].map(renderControlItem)}
    </List>
  );
};
