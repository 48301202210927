import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { AllMapLayers } from 'components/Map/constants';
import { GeoEvent, Report } from 'shared/types';
import { getDateFormatted, getResponsiveFontSize } from 'shared/utils';
import RichTextContent from 'components/RichTextContent';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useEffect } from 'react';
import Map from '../Map';
import PlatformShareIcon from '../PlatformShareIcon';
import ReportMetadata from '../ReportMetadata';
import TextLogo from '../TextLogo';
import useGeoEventQuery from '../../hooks/useGeoEventQuery';

type ReportDetailProps = {
  report: Report;
};

const useStyles = makeStyles()(
  ({ spacing, palette, shape, breakpoints, typography }) => ({
    root: {
      backgroundColor: palette.common.black,
      color: palette.common.white,
      minHeight: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100vw'
    },
    title: {
      flexGrow: 1,
      paddingLeft: '20px',
      fontSize: getResponsiveFontSize(typography.pxToRem(20))
    },
    header: {
      position: 'sticky',
      top: 0,
      background: 'rgba(0, 0, 0, 0.6)',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingTop: `env(safe-area-inset-top)`,
      paddingBottom: spacing(1),
      zIndex: 1
    },
    textLogo: {
      height: 14,
      width: 138,
      marginTop: 10,
      fill: '#FFFFFF'
    },

    backButton: {
      position: 'relative'
    },

    container: {
      flexWrap: 'initial',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row'
      }
    },
    content: {
      [breakpoints.down('sm')]: {
        marginRight: spacing(3),
        marginLeft: spacing(3)
      }
    },
    message: {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
      paddingRight: spacing(0.5)
    },
    link: {
      color: palette.primary.main
    },
    map: {
      borderRadius: shape.borderRadius,
      height: '75vw',
      maxHeight: 300,
      overflow: 'hidden'
    },
    notificationType: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    pinchZoomWrapper: {
      width: 'auto',
      height: 'auto'
    }
  })
);

const ReportDetail = (props: ReportDetailProps): JSX.Element => {
  const { report } = props;
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { geoEvent } = useGeoEventQuery<GeoEvent>();

  const media = report?.media?.[0] || {};
  const imgSrc = media?.url
    ? `${import.meta.env.VITE_BASE_MEDIA_URL}${media.url}`
    : '';

  const { lat, lng } = media;

  const getIncidentPath = (): string => {
    const isLegacyIncident = location.pathname.includes('incident');
    return isLegacyIncident
      ? `/incident/${report.incidentId}`
      : `/i/${report.geoEventId}`;
  };

  const handleGoBack = (): void => {
    history.push(getIncidentPath());
  };

  const handleShareClick = async (): Promise<void> => {
    try {
      // remove leading slash
      const urlPrefix = getIncidentPath().slice(1);
      await Share.share({
        title: report.message,
        text: report.message,
        url: `${import.meta.env.VITE_BASE_SHARE_URL}${urlPrefix}/reports/${
          report.id
        }`,
        dialogTitle: 'Share Report'
      });
    } catch (error) {
      // ignore failures
    }
  };

  // Set the status bar background to black to match this page.
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#000000' });
      StatusBar.setStyle({ style: Style.Dark });
    }
  }, []);

  // Our image viewer
  return (
    <Box className={classes.root}>
      <Grid
        item
        container
        justifyContent="space-between"
        className={classes.header}
      >
        <IconButton
          edge="start"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleGoBack}
          color="inherit"
          size="large"
        >
          <ArrowBackIcon className={classes.backButton} />
          <Typography variant="h6" className={classes.title}>
            <TextLogo className={classes.textLogo} />
          </Typography>
        </IconButton>
        {Capacitor.getPlatform() !== 'web' && (
          <IconButton color="inherit" onClick={handleShareClick} size="large">
            <PlatformShareIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item container spacing={1} className={classes.container}>
        <Grid item md={6} lg={8}>
          <TransformWrapper>
            <TransformComponent
              contentClass={classes.pinchZoomWrapper}
              wrapperClass={classes.pinchZoomWrapper}
            >
              <img
                src={imgSrc}
                alt="Media"
                width="100%"
                data-testid="report-image"
              />
            </TransformComponent>
          </TransformWrapper>
        </Grid>
        <Grid item container direction="column" spacing={2} md={6} lg={4}>
          <Grid item className={classes.content}>
            <Typography color="secondary" variant="body2">
              {getDateFormatted(report.dateCreated, {
                includeYear: !geoEvent?.isActive
              })}
            </Typography>
          </Grid>
          {report.message && (
            <Grid
              item
              sx={{
                marginRight: { xs: 3, sm: 0 },
                marginLeft: { xs: 3, sm: 0 },
                marginTop: -1,
                marginBottom: -1
              }}
            >
              <RichTextContent
                content={report.message}
                className={classes.message}
              />
            </Grid>
          )}
          <Grid item className={classes.content}>
            <Typography
              gutterBottom
              variant="subtitle2"
              color="secondary"
              className={classes.notificationType}
            >
              <ReportMetadata report={report} />
            </Typography>
          </Grid>
          <Grid item className={classes.content}>
            {lat && lng && (
              <Box className={classes.map}>
                <Map
                  center={{ lat, lng }}
                  zoom={12}
                  noControls
                  disableMapLayers={AllMapLayers}
                >
                  <Map.Markers
                    locations={report}
                    type="media"
                    isFadable={false}
                  />
                  <Map.CenterView locations={[report]} />
                </Map>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportDetail;
