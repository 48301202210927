import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePoisState } from 'state/usePoisState';
import { MeasureDistanceToolDialogContent } from 'components/Map/MeasureDistanceToolDialogContent';
import { useMeasureDistanceToolState } from 'state/useMeasureDistanceToolState';
import { useMap } from 'react-map-gl/maplibre';
import { MapToolsMenuItemProps } from './types';

const MeasureDistanceMenuItem = (props: MapToolsMenuItemProps): JSX.Element => {
  const { position, onClick, padding } = props;
  const { setSelectedPoi } = usePoisState();
  const { activateTool } = useMeasureDistanceToolState();
  const { t } = useTranslation();
  const { current: map } = useMap();

  const handleMeasureDistance = (lat: number, lng: number): void => {
    if (!map) {
      return;
    }
    activateTool({ lat, lng }, map);
    setSelectedPoi({
      type: 'measureTool',
      PoiDialogContent: () => <MeasureDistanceToolDialogContent />
    });
  };

  return (
    <MenuItem
      onClick={() => {
        handleMeasureDistance(position.lat, position.lng);
        onClick();
      }}
      sx={{ padding }}
    >
      {t('map.contextMenu.measureDistanceBtnTitle')}
    </MenuItem>
  );
};

export default MeasureDistanceMenuItem;
