import { ReactNode, useEffect, useRef } from 'react';
import { useAlertCamera } from 'hooks/useAlertCameras';
import { useSelectedCameraId } from 'hooks/useSelectedCameraId';
import { useAlertCameraPlayerState } from './useAlertCameraPlayerState';
import { useCameraRedirectOnScreenRotation } from './useCameraRedirectOnScreenRotation';

type AlertCameraPlayerProviderProps = {
  children: ReactNode;
  testOnlyNoAutoFrameAdvance?: boolean;
};

const TIMELAPSE_FRAME_INTERVAL_MS = 200;

const DEFAULT_TIMELAPSE_REPLAY_SEC_PTZ = 60 * 15;
const DEFAULT_TIMELAPSE_REPLAY_SEC_STATIONARY = 60 * 60;

/**
 * A custom provider component for centralized state and side-effect management of
 * the alert camera player.
 *
 * Key Features:
 * - Maintains global player state (status, timelapse progression)
 * - Handles device orientation changes for fullscreen mode
 * - Adjusts timelapse replay time based on camera type (PTZ/stationary)
 *
 * Usage:
 * Place at the root level of the app, outside specific routes/layouts,
 * to ensure consistent player state across navigation changes.
 *
 * Note:
 * - Manages state and side effects only; does not render UI
 *
 * Key differences from `useAlertCameraPlayerState()`:
 * 1. Handles player side effects
 * 2. Consumes the player state (rather than storing it)
 * 3. Provides additional logic for orientation and replay time adjustments
 */
export const AlertCameraPlayerProvider = (
  props: AlertCameraPlayerProviderProps
): JSX.Element => {
  const { children, testOnlyNoAutoFrameAdvance = false } = props;
  const cameraId = useSelectedCameraId();
  const { alertCamera: camera } = useAlertCamera({
    id: cameraId,
    refetchInterval: 1000 * 15
  });
  const cameraPlayer = useAlertCameraPlayerState(camera);
  const timelapseFrameIntervalRef = useRef<NodeJS.Timeout>();

  const {
    playerStatus,
    timelapseReplayTime,
    stopTimelapse,
    setTimelapseReplayTime,
    advanceTimelapseFrame
  } = cameraPlayer;

  useCameraRedirectOnScreenRotation(playerStatus, camera);

  // Effect: Reset player state when camera changes
  useEffect(() => {
    if (!camera?.id) return;
    stopTimelapse();
    setTimelapseReplayTime(
      camera?.hasPtz
        ? DEFAULT_TIMELAPSE_REPLAY_SEC_PTZ
        : DEFAULT_TIMELAPSE_REPLAY_SEC_STATIONARY
    );
    // NOTE: the `camera` object changes every time cameras are re-fetched, so
    // do not rely on it directly in the dependency array.
  }, [camera?.id, camera?.hasPtz, stopTimelapse, setTimelapseReplayTime]);

  // Effect: Stop playback when replay time changes
  useEffect(() => {
    stopTimelapse();
  }, [timelapseReplayTime, stopTimelapse]);

  // Effect: Manage timelapse frame advancement
  // Causes the playback timer to fire when the player is in "playing" state.
  // Similarly causes it to stop when the player is in any other state, or reset
  // when any data used within the setInterval() callback changes.
  useEffect(() => {
    if (playerStatus !== 'playingTimelapse' || testOnlyNoAutoFrameAdvance) {
      return () => {};
    }

    timelapseFrameIntervalRef.current = setInterval(
      () => advanceTimelapseFrame(),
      TIMELAPSE_FRAME_INTERVAL_MS
    );

    return () => {
      clearInterval(timelapseFrameIntervalRef.current);
      timelapseFrameIntervalRef.current = undefined;
    };
  }, [playerStatus, advanceTimelapseFrame, testOnlyNoAutoFrameAdvance]);

  return <>{children}</>;
};
