import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Alert,
  AlertTitle
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Photo } from '@watchduty/camera';
import { API } from 'api';
import FilePicker from 'components/FilePicker';
import GeoEventChangelog from 'components/GeoEventChangelog';
import GeoEventEvacuations from 'components/GeoEventEvacuations';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import ReportImagePreview from 'components/ReportImagePreview';
import RichTextEditor from 'components/RichTextEditor';
import {
  ChangeEvent,
  ClipboardEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'react-map-gl/maplibre';
import { useHistory } from 'react-router-dom';
import {
  GeoEvent,
  GeoEventCreateUpdateData,
  NotificationType,
  UploadImageData
} from 'shared/types';
import {
  getEvacDescription,
  getEvacZoneStyleFromGeoEvent,
  getGPSDataFromFileAndroid,
  getGPSDataFromFileIOS,
  getRichTextContent,
  readFilePath,
  removeHTMLTags,
  uploadImage
} from 'shared/utils';
import { useSnackbarState } from 'state';
import { makeStyles } from 'tss-react/mui';
import { InternalNotesInput } from 'components/InternalNotesInput';
import { useGeoEventEvacZones } from 'hooks/useGeoEventEvacZones';
import { NotificationType as NotificationTypeConstant } from '../../../constants';
import {
  getRedundancyMessage,
  getValuesForServer,
  getFormValidationSchema,
  getInitialFormValues,
  validateReport
} from './utils';
import { ReportNotificationPreview } from './ReportNotificationPreview';
import { assetOptions, statusOptions } from './constants';
import { FormValues } from './types';
import { useGeoEvent } from '../../../hooks/useGeoEvent';
import { ReportMap } from './ReportMap';
import { MediaForm } from './MediaForm';

type ReportFormProps = {
  geoEvent: GeoEvent;
};

type PostReportData = {
  media:
    | [
        {
          lat: string | null;
          lng: string | null;
          az: number | null;
          url: string;
        }
      ]
    | [];
  embedUrl: string;
  geoEventId: number | null;
  message: string;
  notificationType: NotificationType;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const postReport = (data: PostReportData, skipRedundancyCheck?: boolean) => {
  let url = '/reports';
  if (skipRedundancyCheck) {
    url += '/?skip_redundancy_check=true';
  }
  return API.post(url, data);
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  formContainer: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: 720
  },
  form: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)'
  },
  notificationLabel: {
    textTransform: 'capitalize'
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    '& #field-control-asset': {
      color: theme.palette.text.secondary
    }
  },
  evacButton: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(-1)
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    height: 48
  }
}));

const ReportForm = (props: ReportFormProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [postError, setPostError] = useState(false);
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('laptop'), {
    defaultMatches: !Capacitor.isNativePlatform()
  });
  const queryClient = useQueryClient();

  const cacheBuster = useMemo(() => Date.now(), []);

  const { geoEventEvacZones: availableZones } = useGeoEventEvacZones(geoEvent);

  const evacZoneStyle = getEvacZoneStyleFromGeoEvent(geoEvent);

  const hasEvacZones = availableZones.length > 0;
  const validationSchema = getFormValidationSchema(t, hasEvacZones);
  const initialValues = getInitialFormValues(geoEvent);

  const { saveGeoEvent } = useGeoEvent({ id: geoEvent?.id });

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur'
  });

  const {
    control,
    formState,
    setValue,
    getValues,
    setError,
    handleSubmit,
    watch,
    reset
  } = formMethods;

  const { isPrescribed } = geoEvent.data;

  // GeoEvent changed (we probably fetched an updated version). Since hook-form
  // doesn't see updates to initialValues, we need to reset the form manually.
  useEffect(() => {
    reset(getInitialFormValues(geoEvent));
  }, [reset, geoEvent, getValues]);

  const s3AuthQuery = useQuery({
    queryKey: ['gets3Auth'],
    queryFn: () => API.get('media/storage_auth/')
  });

  const uploadImageMutation = useMutation({
    mutationFn: (data: UploadImageData) => uploadImage(data)
  });

  const postReportMutation = useMutation({
    mutationFn: (update: {
      data: PostReportData;
      skipRedundancyCheck?: boolean;
    }) => postReport(update.data, update.skipRedundancyCheck)
  });

  const handleFilePicked = async (
    newFile: File | File[] | Photo
  ): Promise<void> => {
    if (Capacitor.getPlatform() === 'web') {
      // this is from the Web. We get an array, even though we are only handling 1 photo for now
      const webFile = Array.isArray(newFile) ? newFile[0] : newFile;

      if (!('type' in webFile)) return;

      // type is the image mime type ie: 'image/jpeg'
      const imageTypeIndex = 1;
      const parsedFileType = webFile.type.split('/')[imageTypeIndex];

      setValue('fileType', parsedFileType);
      setValue('media', webFile);

      return;
    }

    if ('format' in newFile) {
      const gpsData =
        Capacitor.getPlatform() === 'ios'
          ? getGPSDataFromFileIOS(newFile)
          : getGPSDataFromFileAndroid(newFile);

      const { bearing, lat, lng } = gpsData;

      if (lat && lng) {
        setValue('lat', lat.toString());
        setValue('lng', lng.toString());
      }

      if (bearing) {
        setValue('az', bearing);
      }

      const res = await readFilePath(newFile);
      if (!res) return;

      setValue('fileType', res.type);
      setValue('media', res.file);
    }
  };

  const handleInvalidFiles = (error: string): void => {
    setError('media', { message: error });
  };

  const handleCoordsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Allow users to paste `lat, lng` as a string and lets parse it for them
    const val = e.target.value;
    const name = e.target.name as 'lat' | 'lng';

    const latLng = val.split(/, ?/);

    if (latLng.length === 2) {
      // User pasted string values
      const [lat, lng] = latLng;

      setValue('lat', lat);
      setValue('lng', lng);
      return;
    }

    setValue(name, val);
  };

  const goBack = (geoEventId: number): void => {
    // We handle the goback differently for reporters so we force a full page
    // refresh with history.push.
    history.push(`/i/${geoEventId}`);
  };

  const handleSubmitReport: SubmitHandler<FormValues> = async (values) => {
    const { activeEvacuations, ...data } = values;
    const {
      isActive,
      acreage,
      containment,
      evacuationNotes,
      evacuationOrders,
      evacuationWarnings,
      evacuationAdvisories,
      isFps,
      evacZoneStatuses,
      confirmSelectedZones,
      customOrders,
      customWarnings,
      customAdvisories,
      reporterOnlyNotes,
      ...reportData
    } = getValuesForServer(data);

    try {
      const geoEventUpdate: GeoEventCreateUpdateData = {
        ...geoEvent,
        isActive,
        data: {
          ...geoEvent.data,
          acreage,
          containment,
          evacuationNotes: getEvacDescription({
            description: evacuationNotes,
            custom: false,
            hasEvacZones: false
          }),
          evacuationOrders: getEvacDescription({
            description: evacuationOrders,
            custom: customOrders,
            hasEvacZones
          }),
          evacuationWarnings: getEvacDescription({
            description: evacuationWarnings,
            custom: customWarnings,
            hasEvacZones
          }),
          evacuationAdvisories: getEvacDescription({
            description: evacuationAdvisories,
            custom: customAdvisories,
            hasEvacZones
          }),
          isFps,
          reporterOnlyNotes: getRichTextContent(reporterOnlyNotes)
        },
        evacZoneStatuses: evacZoneStatuses.map((eZS) => ({
          status: eZS.status,
          evacZone: { id: eZS.evacZone.id }
        }))
      };

      await saveGeoEvent(geoEventUpdate, geoEvent);

      if (values.media && reportData.media[0] && s3AuthQuery.data) {
        await uploadImageMutation.mutateAsync({
          media: values.media,
          s3AuthData: s3AuthQuery.data,
          s3Key: reportData.media[0].url
        });
      }

      await postReportMutation.mutateAsync({ data: reportData });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['geoEvents']
        }),
        queryClient.invalidateQueries({
          queryKey: ['geoEvent', geoEvent.id]
        }),
        queryClient.invalidateQueries({
          queryKey: ['reports', geoEvent.id]
        })
      ]);

      setSnackbar(t('addIncidentReport.successMessage'), 'success');
      goBack(geoEvent.id);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorStatus = error?.response?.status;

      if (errorStatus === 403) {
        setPostError(true);
      } else if (errorStatus === 409) {
        // We should check for redundant posts (this only affects CIOs)
        // If there are too many posts in the last few minutes, we should warn them
        // `confirm` is super ghetto but its only seen by CIO's
        // eslint-disable-next-line
        const r = confirm(getRedundancyMessage(error.response));

        if (!r) return;

        try {
          await postReportMutation.mutateAsync({
            data: reportData,
            skipRedundancyCheck: true
          });
          setSnackbar(t('addIncidentReport.successMessage'), 'success');
          goBack(geoEvent.id);
        } catch (finalError) {
          setSnackbar(t('common.unknownErrorTryAgain'), 'error');
        }
      } else {
        setSnackbar(t('common.unknownErrorTryAgain'), 'error');
      }
    }
  };

  const [asset, media, isFps, activeEvacuations, notificationType] = watch([
    'asset',
    'media',
    'isFps',
    'activeEvacuations',
    'notificationType'
  ]);

  const handlePaste: ClipboardEventHandler<HTMLFormElement> = (event) => {
    /**
     * This handles copy-paste on the web.  In order to use,
     * the user must first click on the page to paste
     */
    if (Capacitor.getPlatform() !== 'web' || asset !== 'image') return;

    const clipboardItems = event.clipboardData.items;

    const items: DataTransferItem[] = [].slice
      .call(clipboardItems)
      .filter((item: DataTransferItem) => item.type.indexOf('image') !== -1);

    if (items.length === 0) {
      return;
    }

    const item = items[0];
    // Get the blob of image
    const blob = item.getAsFile();

    setValue('media', blob);
  };

  if (s3AuthQuery.isLoading) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <MapProvider>
      <FormProvider {...formMethods}>
        <Box
          className={classes.root}
          sx={{
            flexDirection: isLargeMediaQuery ? 'row' : 'column',
            overflow: isLargeMediaQuery ? 'hidden' : 'auto'
          }}
        >
          <Box
            sx={{
              flex: 1,
              minHeight: isLargeMediaQuery ? '100%' : 314,
              display: 'flex'
            }}
          >
            <ReportMap
              geoEvent={geoEvent}
              zoomControlEnabled={isLargeMediaQuery}
              cacheBuster={cacheBuster}
            />
          </Box>
          <Container
            maxWidth={false}
            className={isLargeMediaQuery ? classes.formContainer : undefined}
          >
            <form
              onSubmit={handleSubmit(handleSubmitReport)}
              noValidate
              className={classes.form}
              onPaste={handlePaste}
            >
              <Grid container spacing={2}>
                {postError && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      <AlertTitle>
                        {t('addIncidentReport.postError.title')}
                      </AlertTitle>
                      <Typography>
                        {t('addIncidentReport.postError.description')}
                      </Typography>
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t('addIncidentReport.sections.incident')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="geoEventId"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-incident"
                          label={t('addIncidentReport.inputs.incident.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.notificationLabel}
                          disabled
                        >
                          <MenuItem
                            value={geoEvent.id}
                            className={classes.notificationLabel}
                          >
                            {geoEvent.name}
                          </MenuItem>
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-status"
                          label={t('addIncidentReport.inputs.status.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.notificationLabel}
                          disabled={formState.isSubmitting}
                        >
                          {statusOptions.map(({ label, value }) => {
                            return (
                              <MenuItem
                                key={label}
                                value={value}
                                className={classes.notificationLabel}
                              >
                                {label}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="acreage"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, value, onChange, ...muiFieldProps } =
                            field;
                          return (
                            <TextField
                              id="field-control-acreage"
                              label={t(
                                'addIncidentReport.inputs.acreage.label'
                              )}
                              fullWidth
                              {...muiFieldProps}
                              value={value ?? ''}
                              onChange={(e) =>
                                onChange(
                                  e.target.value ? Number(e.target.value) : null
                                )
                              }
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              type="number"
                              inputProps={{
                                pattern: '[0-9]+([.,][0-9]+)?',
                                inputMode: 'decimal',
                                onWheel: (e) => e.currentTarget.blur()
                              }}
                              disabled={formState.isSubmitting}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="containment"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, value, onChange, ...muiFieldProps } =
                            field;
                          return (
                            <TextField
                              id="field-control-containment"
                              label={t(
                                'addIncidentReport.inputs.containment.label'
                              )}
                              fullWidth
                              {...muiFieldProps}
                              value={value ?? ''}
                              onChange={(e) =>
                                onChange(
                                  e.target.value
                                    ? parseInt(e.target.value, 10)
                                    : null
                                )
                              }
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              type="number"
                              inputProps={{
                                pattern: '[0-9]+([.,][0-9]+)?',
                                inputMode: 'numeric',
                                onWheel: (e) => e.currentTarget.blur()
                              }}
                              disabled={
                                formState.isSubmitting || !!isPrescribed
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="isFps"
                    control={control}
                    render={({ field }): JSX.Element => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isPrescribed"
                              checked={field.value}
                              onChange={(event) =>
                                field.onChange(event.target.checked)
                              }
                            />
                          }
                          label={t('addIncidentReport.forwardProgressStopped')}
                          disabled={formState.isSubmitting || !!isPrescribed}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="activeEvacuations"
                    control={control}
                    render={({ field }): JSX.Element => {
                      return (
                        <Button
                          variant="text"
                          className={classes.evacButton}
                          startIcon={
                            activeEvacuations ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <ChevronRightIcon />
                            )
                          }
                          onClick={() => field.onChange(!field.value)}
                          disabled={formState.isSubmitting}
                        >
                          {t('addIncidentReport.activeEvacuations')}
                        </Button>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* Ideally we would use MUI's <Collapse /> here, but there
                  are some performance problems with iOS Safari:
                  https://trello.com/c/mc3v3rWy/1045-slowdown-on-certain-add-report-pages */}
                  <Box display={activeEvacuations ? 'block' : 'none'}>
                    <GeoEventEvacuations
                      evacZones={availableZones}
                      zoneStyle={evacZoneStyle}
                    />
                  </Box>
                </Grid>

                <InternalNotesInput
                  initialValue={initialValues.reporterOnlyNotes}
                />

                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t('addIncidentReport.sections.update')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="notificationType"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-notificationType"
                          label={t(
                            'addIncidentReport.inputs.notificationType.label'
                          )}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.notificationLabel}
                          disabled={
                            geoEvent.notificationType ===
                              NotificationType.Silent || formState.isSubmitting
                          }
                        >
                          {Object.values(NotificationTypeConstant).map(
                            (notifType) => {
                              return (
                                <MenuItem
                                  key={notifType}
                                  value={notifType}
                                  className={classes.notificationLabel}
                                >
                                  {notifType}
                                </MenuItem>
                              );
                            }
                          )}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="messageHtml"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const reportMessageWarning = validateReport({
                        t,
                        report: removeHTMLTags(field.value),
                        fpsChecked: isFps,
                        isSilent: notificationType === NotificationType.Silent
                      });
                      return (
                        <>
                          <RichTextEditor
                            id="field-control-message"
                            label={t(
                              'addIncidentReport.inputs.description.label'
                            )}
                            initialValue={initialValues.messageHtml}
                            onChange={(html, text) => {
                              field.onChange(html);
                              setValue('messageText', text);
                            }}
                            editable={!formState.isSubmitting}
                            error={!!fieldState.error}
                            required
                            warning={!!reportMessageWarning}
                          />
                          {!!(fieldState.error || reportMessageWarning) && (
                            <FormHelperText
                              error={!!fieldState.error}
                              sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                              data-testid="report-validation-message"
                            >
                              {fieldState.error?.message ||
                                reportMessageWarning}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t('addIncidentReport.sections.media')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="asset"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, onChange, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-asset"
                          label={t('addIncidentReport.inputs.asset.label')}
                          fullWidth
                          {...muiFieldProps}
                          value={field.value ?? 'placeholder'}
                          onChange={(e) =>
                            onChange(
                              e.target.value === 'placeholder'
                                ? null
                                : e.target.value
                            )
                          }
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          className={cx(
                            classes.notificationLabel,
                            !asset && classes.textSecondary
                          )}
                          disabled={formState.isSubmitting}
                        >
                          <MenuItem
                            value="placeholder"
                            className={cx(
                              classes.notificationLabel,
                              classes.textSecondary
                            )}
                          >
                            {t('addIncidentReport.inputs.asset.options.type')}
                          </MenuItem>
                          {assetOptions.map(({ transLabelKey, value }) => {
                            return (
                              <MenuItem
                                key={transLabelKey}
                                value={value as string}
                                className={classes.notificationLabel}
                              >
                                {t(transLabelKey)}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                {asset === 'socialEmbed' && (
                  <Grid item xs={12}>
                    <Controller
                      name="embedUrl"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-embedUrl"
                            label={t('addIncidentReport.inputs.embedUrl.label')}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={
                              fieldState.error?.message ||
                              t('addIncidentReport.inputs.embedUrl.helperText')
                            }
                            disabled={formState.isSubmitting}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}

                {asset && asset !== 'socialEmbed' && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="media"
                          control={control}
                          render={({ field, fieldState }): JSX.Element => {
                            if (!field.value) {
                              return (
                                <FilePicker
                                  message={t(
                                    `addIncidentReport.inputs.${asset}.label`
                                  )}
                                  disabled={formState.isSubmitting}
                                  error={fieldState.error?.message}
                                  onFilesPicked={handleFilePicked}
                                  onFilesInvalid={handleInvalidFiles}
                                />
                              );
                            }
                            return (
                              <ReportImagePreview
                                data={field.value}
                                onDelete={() => {
                                  setValue('media', null);
                                  setValue('fileType', '');
                                  setValue('lat', '');
                                  setValue('lng', '');
                                  setValue('az', 0);
                                }}
                              />
                            );
                          }}
                        />
                      </Grid>

                      {media && (
                        <Grid item xs={12} sm={6}>
                          <Grid item container spacing={2}>
                            <Grid item xs={6}>
                              <Controller
                                name="lat"
                                control={control}
                                render={({
                                  field,
                                  fieldState
                                }): JSX.Element => {
                                  const { ref, ...muiFieldProps } = field;
                                  return (
                                    <TextField
                                      id="field-control-lat"
                                      label={t(
                                        'addIncidentReport.inputs.latitude.label'
                                      )}
                                      fullWidth
                                      {...muiFieldProps}
                                      inputRef={ref}
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                      placeholder="00.000000"
                                      onChange={handleCoordsChange}
                                      inputProps={{
                                        pattern: '[0-9]+([.,][0-9]+)?'
                                      }}
                                      disabled={formState.isSubmitting}
                                    />
                                  );
                                }}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Controller
                                name="lng"
                                control={control}
                                render={({
                                  field,
                                  fieldState
                                }): JSX.Element => {
                                  const { ref, ...muiFieldProps } = field;
                                  return (
                                    <TextField
                                      id="field-control-lng"
                                      label={t(
                                        'addIncidentReport.inputs.longitude.label'
                                      )}
                                      fullWidth
                                      {...muiFieldProps}
                                      inputRef={ref}
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                      placeholder="00.000000"
                                      onChange={handleCoordsChange}
                                      inputProps={{
                                        pattern: '[0-9]+([.,][0-9]+)?'
                                      }}
                                      disabled={formState.isSubmitting}
                                    />
                                  );
                                }}
                              />
                            </Grid>

                            <MediaForm geoEvent={geoEvent} />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box sx={{ paddingTop: 2, paddingBottom: 1 }}>
                    <Typography variant="h3">
                      {t('addIncidentReport.sections.preview')}
                    </Typography>
                  </Box>
                </Grid>

                <ReportNotificationPreview geoEvent={geoEvent} />

                <GeoEventChangelog
                  geoEvent={geoEvent}
                  evacZones={availableZones}
                  zoneStyle={evacZoneStyle}
                />

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.button}
                    disabled={formState.isSubmitting}
                  >
                    {formState.isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('addIncidentReport.button')
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </FormProvider>
    </MapProvider>
  );
};

export default ReportForm;
