import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { focusManager } from '@tanstack/react-query';
import { MapProvider } from 'react-map-gl/maplibre';
import {
  useAuthState,
  useCacheState,
  useMapState,
  useScrollPositionState
} from 'state';
import { GeoEvent, Location, LatLng } from 'shared/types';
import { usePoisState } from 'state/usePoisState';
import { isMobile } from 'shared/utils';
import { IncidentMapStateUpdate } from 'state/useMapState';
import LocationsLayer from 'components/Map/layers/LocationsLayer';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useGeoEventQuery from 'hooks/useGeoEventQuery';
import useGeoEvents from 'hooks/useGeoEvents';
import useSearchDrawerState from 'state/useSearchDrawerState';
import useReportsQuery from 'hooks/useReportsQuery';
import useContextMenu from 'hooks/useContextMenu';
import useMapLongPress from 'hooks/useMapLongPress';
import useSelectedLocationState from 'state/useSelectedLocationState';
import ElectricLinesLayer from 'components/Map/layers/TransmissionLinesLayer/ElectricLinesLayer';
import GasPipelineLayer from 'components/Map/layers/TransmissionLinesLayer/GasPipelineLayer';
import { Route, Switch } from 'react-router-dom';
import { AlertCamerasLayer } from 'components/Map/layers/AlertCamerasLayer';
import { PrivateLandOwnershipLayer } from 'components/Map/layers/PrivateLandOwnershipLayer';
import { Capacitor } from '@capacitor/core';
import { MeasureDistanceToolLayer } from 'components/Map/layers/MeasureDistanceToolLayer';
import { SEARCH_DRAWER_WIDTH } from '../../constants';
import Map from '../Map';
import GeoEventsLayer from '../Map/layers/GeoEventsLayer';
import { SearchDrawer } from './SearchDrawer';
import { CenterMap } from './CenterMap';
import { MapPinDialogContent } from '../Map/MapPinDialogContent';
import MapToolsContextMenu from '../Map/MapToolsContextMenu';
import useMapLayersState from '../../state/useMapLayersState';
import { MapLayers } from '../Map/constants';
import ClearTimeoutMap from './ClearTimeoutMap';
import { DrawerRefContent } from '../Map/MapEntityDrawer';
import { StructuredEvacuationsViewLayer } from '../Map/layers/StructuredEvacuationsLayer';
import { MapPinMarker } from './MapPinMarker';

type IncidentsMapProps = {
  drawerRef: RefObject<DrawerRefContent | null>;
  initialLat?: number;
  initialLng?: number;
  initialZoom?: number;
};

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  root: {
    flex: 1,
    display: 'flex'
  },
  mapContainer: {
    flexGrow: 1,
    position: 'relative'
  },
  mapContainerLargeFormat: {
    flexGrow: 1,
    position: 'relative',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -SEARCH_DRAWER_WIDTH,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    })
  }
}));

const IncidentsMap = (props: IncidentsMapProps): JSX.Element => {
  const { drawerRef, initialLat, initialLng, initialZoom } = props;
  const {
    incidentMapState,
    updateIncidentMapState,
    resetReportMapState,
    setActiveMapBounds
  } = useMapState();
  const { reset: resetScrollState } = useScrollPositionState();
  const {
    permissions: { canReport, isInternalUser },
    showMembershipProFeatures
  } = useAuthState();
  const lat = initialLat || incidentMapState.center.lat;
  const lng = initialLng || incidentMapState.center.lng;
  const center = useMemo(() => ({ lat, lng }), [lat, lng]);
  const { setSelectedPoi } = usePoisState();
  const [mapLongPressCoordinates, setMapLongPressCoordinates] =
    useState<LatLng | null>(null);
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const isMobileApp = isMobile();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const { open } = useSearchDrawerState();
  const { classes } = useStyles({ open });
  const { selectedLocation, setSelectedLocation } = useSelectedLocationState();
  const mapLayersState = useMapLayersState();
  const { setCacheState } = useCacheState();

  useEffect(() => {
    // On web, we need to invalidate the cache whenever the selected geoEventId changes
    // to avoid showing stale data due to push notifications never invalidating the cache
    if (!Capacitor.isNativePlatform() && selectedGeoEventId) {
      // round down to the second so we have some form of cache hit similarity
      setCacheState(Math.round(Date.now() / 1000) * 1000);
    }
  }, [selectedGeoEventId, setCacheState]);

  useEffect(() => {
    if (isInternalUser && initialLat && initialLng) {
      const mapPin = {
        lat: initialLat,
        lng: initialLng
      };

      setSelectedPoi({
        coordinates: mapPin,
        type: 'mapPin',
        PoiDialogContent: () => <MapPinDialogContent />
      });
    }
  }, [isInternalUser, initialLat, initialLng, setSelectedPoi]);

  // Because we use history.goBack from the addIncident form - we need to set focus on the page to ensure
  // we refetch the tiles query and reporters see the newly created incident.
  // Otherwise the query uses the disk cache and does not refetch, even with modifications to gcTime/staleTime
  useEffect(() => {
    if (canReport) {
      focusManager.setFocused(true);
    }
  }, [canReport]);

  const { allGeoEvents, wildfireEvents, locations } = useGeoEvents();
  const { geoEvent: selectedGeoEvent } =
    useGeoEventQuery<GeoEvent | Location>();

  // Use the list data to speed up the re-center behavior when you get to the map so we don't have to wait for the
  // second query to possibly finish
  const selectedLatLng: LatLng | null = useMemo(() => {
    const listGeoEvent = allGeoEvents.find(
      (item) => item?.id === selectedGeoEventId
    );
    if (listGeoEvent) {
      return { lat: listGeoEvent.lat, lng: listGeoEvent.lng };
    }
    if (selectedGeoEvent) {
      return { lat: selectedGeoEvent.lat, lng: selectedGeoEvent.lng };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeoEventId, selectedGeoEvent]);

  const reportsQuery = useReportsQuery(selectedGeoEventId);
  const reports = reportsQuery.data?.data || [];

  // Resetting ReportMap State so that it re-centers correctly
  // when viewing the report map.
  useEffect(() => {
    resetReportMapState();
    resetScrollState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update incident map state when the user pan / zooms the map
  // and set tiles so we re-query for new geo-events
  const onViewportChange = useCallback(
    (viewport: IncidentMapStateUpdate) => {
      updateIncidentMapState(viewport);
      setActiveMapBounds(viewport.bounds);
    },
    [updateIncidentMapState, setActiveMapBounds]
  );

  const handleMapClick = useCallback(
    (e: maplibregl.MapLayerMouseEvent) => {
      if (selectedLocation) {
        setSelectedLocation(null);
      }
    },
    [selectedLocation, setSelectedLocation]
  );

  const handleMapLongPress = useCallback(
    (coordinates: LatLng): void => {
      if (!isInternalUser) {
        return;
      }
      setMapLongPressCoordinates(coordinates);
    },
    [isInternalUser]
  );

  const { ctxMenuClose, contextMenuCoordinates, openContextMenu } =
    useContextMenu();

  const { onTouchStart, clearMobileTimeout } = useMapLongPress({
    onLongPress: handleMapLongPress
  });

  const zoom = useMemo(
    () => initialZoom || incidentMapState.zoom,
    [incidentMapState.zoom, initialZoom]
  );

  // in the case of an inactive geo event linked from google search or direct link, we're no longer
  // returning that data in the list view, so we manually add this icon onto the map
  const showHiddenWildFire =
    selectedGeoEvent &&
    selectedGeoEvent.geoEventType === 'wildfire' &&
    !selectedGeoEvent.isVisible;

  const showHiddenLocation =
    selectedGeoEvent &&
    selectedGeoEvent.geoEventType === 'location' &&
    !selectedGeoEvent.isVisible;

  const containerClass = isLargeMediaQuery
    ? classes.mapContainerLargeFormat
    : classes.mapContainer;

  return (
    <MapProvider>
      <Box className={classes.root}>
        <Box
          className={containerClass}
          onMouseDown={
            isMobileApp ? undefined : () => drawerRef.current?.minimize()
          }
          onTouchStart={
            isMobileApp ? () => drawerRef.current?.minimize() : undefined
          }
          onContextMenu={(e) => {
            // It's been added to prevent opening native context menu when MUI menu was open.
            e.preventDefault();
          }}
        >
          <Map
            center={center}
            zoom={zoom}
            onViewportChange={onViewportChange}
            onContextMenu={openContextMenu}
            onTouchStart={onTouchStart}
            onClick={handleMapClick}
          >
            <MeasureDistanceToolLayer />
            <StructuredEvacuationsViewLayer />
            {showMembershipProFeatures && (
              <>
                <ElectricLinesLayer
                  visible={mapLayersState.mapLayers.includes(
                    MapLayers.ELECTRICAL_LINES
                  )}
                />
                <GasPipelineLayer
                  visible={mapLayersState.mapLayers.includes(
                    MapLayers.GAS_PIPELINES
                  )}
                />
                <PrivateLandOwnershipLayer
                  visible={mapLayersState.mapLayers.includes(
                    MapLayers.PRIVATE_LAND_OWNERSHIP
                  )}
                />
              </>
            )}
            <Switch>
              <Route
                path="/camera/:cameraId"
                render={() => (
                  <AlertCamerasLayer
                    visible={mapLayersState.mapLayers.includes(
                      MapLayers.CAMERAS
                    )}
                  />
                )}
              />
              <Route
                path="/"
                render={() => (
                  <AlertCamerasLayer
                    visible={mapLayersState.mapLayers.includes(
                      MapLayers.CAMERAS
                    )}
                  />
                )}
              />
            </Switch>
            {locations && (
              <LocationsLayer
                locations={locations}
                selectedGeoEventId={selectedGeoEventId}
              />
            )}
            {wildfireEvents && (
              <GeoEventsLayer
                geoEvents={wildfireEvents}
                isFadable
                selectedGeoEventId={selectedGeoEventId}
              />
            )}
            {showHiddenWildFire && (
              <Map.WildFireGeoEventMarker
                geoEvent={selectedGeoEvent as unknown as GeoEvent}
              />
            )}
            {showHiddenLocation && (
              <Map.LocationMarker
                location={selectedGeoEvent as unknown as Location}
              />
            )}
            <Map.Markers locations={reports} type="media" />
            <MapPinMarker drawerRef={drawerRef} />
            <Map.MapEvents onViewportChange={onViewportChange} />
            <CenterMap
              zoomIn
              latLng={selectedLatLng}
              drawerIsOpen
              defaultSnapPointPct={0.47}
            />
            <ClearTimeoutMap clearMobileTimeout={clearMobileTimeout} />
            <MapToolsContextMenu
              // This instance is for the "right-click" context menu.
              variant="menu"
              onClose={ctxMenuClose}
              pointCoordinates={contextMenuCoordinates}
              anchorPosition={
                (contextMenuCoordinates && {
                  top: contextMenuCoordinates.mouseY,
                  left: contextMenuCoordinates.mouseX
                }) || { top: 0, left: 0 }
              }
            />
            <MapToolsContextMenu
              // This instance is for map long-presses.
              variant="drawer"
              onClose={() => {
                setMapLongPressCoordinates(null);
              }}
              pointCoordinates={mapLongPressCoordinates}
            />
          </Map>
        </Box>

        <SearchDrawer />
      </Box>
    </MapProvider>
  );
};

export default IncidentsMap;
