import { Layer, LayerProps } from 'react-map-gl/maplibre';
import { usePoisState } from 'state/usePoisState';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { RedFlagWarning } from 'shared/types';
import useMapLayersState from 'state/useMapLayersState';
import ArcGisLayer from './ArcGisLayer';
import addVisible from '../../../shared/addVisible';
import { FILL_PATTERN_LAYER_MIN_ZOOM } from '../constants';
import { RFWDialogContent } from '../RFWDialogContent';
import { useMapLayerEvents } from './useMapLayerEvents';
import { MapLayerProps } from '../types';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../utils';
import { BEFORE_LAYER_ID } from '../styles/constants';

type AlertType = 'Red Flag Warning' | 'Fire Weather Watch';

type RedFlagWarningsProperties = {
  End_: number;
  Event: AlertType;
  OBJECTID: number;
  Start: number;
  Updated: number;
  Link: string;
};

const FEATURE_LAYER_URL =
  'https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/NWS_Watches_Warnings_v1/FeatureServer/6';

const FIELDS = ['Event', 'Start', 'End_', 'Updated', 'Link'];
const RFW_FILL_LAYER_ID = 'rfw-fill-layer';
const RFW_STROKE_LAYER_ID = 'rfw-stroke-layer';
const RFW_SELECTED_FILL_LAYER_ID = 'rfw-selected-fill-layer';
const RFW_FILL_PATTERN_LAYER_ID = 'rfw-fill-pattern-layer';
const RFW_SELECTED_STROKE_LAYER_ID = 'rfw-selected-stroke-layer';

const getFillStyle = (isDark: boolean): LayerProps => ({
  type: 'fill',
  paint: {
    'fill-color': [
      'case',
      ['==', ['get', 'Event'], 'Red Flag Warning'],
      '#FA6FB8',
      '#FF7A00'
    ],
    'fill-opacity': [
      'case',
      ['==', ['get', 'Event'], 'Red Flag Warning'],
      0.3,
      isDark ? 0.5 : 0.3
    ]
  }
});

const patternFillStyle: LayerProps = {
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  paint: {
    'fill-pattern': [
      'case',
      ['==', ['get', 'Event'], 'Red Flag Warning'],
      'RedFlagWarningPattern',
      'FireWeatherWatchPattern'
    ]
  }
};

const strokeStyle: LayerProps = {
  type: 'line',
  paint: {
    'line-color': [
      'case',
      ['==', ['get', 'Event'], 'Red Flag Warning'],
      '#FA6FB8',
      '#FF7A00'
    ],
    'line-opacity': 1,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1.25, 18, 3.25],
    'line-dasharray': [2, 1]
  }
};

const getAlertTitle = (alertType: AlertType, t: TFunction): string => {
  if (alertType === 'Red Flag Warning') {
    return t('map.poi.redFlagWarning.title');
  }
  return t('map.poi.fireWeatherWatch.title');
};

const RedFlagWarningsLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { t } = useTranslation();
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const { isDarkBaseLayer } = useMapLayersState();

  useMapLayerEvents<RedFlagWarningsProperties>({
    layerId: RFW_FILL_LAYER_ID,
    onClick: useCallback(
      (geoJsonFeatures) => {
        const { properties } = geoJsonFeatures[0];

        const warning: RedFlagWarning = {
          id: properties.OBJECTID,
          startTimestamp: properties.Start,
          // eslint-disable-next-line no-underscore-dangle
          endTimestamp: properties.End_,
          updatedTimestamp: properties.Updated,
          link: properties.Link
        };

        setSelectedPoi({
          type: 'rfw',
          id: warning.id,
          PoiDialogContent: () => (
            <RFWDialogContent
              title={getAlertTitle(properties.Event, t)}
              redFlagWarning={warning}
            />
          )
        });
      },
      [t, setSelectedPoi]
    )
  });

  const selectedRFWId = selectedPoi?.type === 'rfw' ? selectedPoi.id || 0 : 0;

  const selectedFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    selectedId: selectedRFWId
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    selectedId: selectedRFWId
  });

  return (
    <ArcGisLayer
      visible={visible}
      src={FEATURE_LAYER_URL}
      where={`Event = 'Red Flag Warning' or Event = 'Fire Weather Watch'`}
      fields={FIELDS}
      staleTime={60 * 60 * 1000} // 60 minutes
    >
      <Layer
        id={RFW_FILL_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(getFillStyle(isDarkBaseLayer), visible)}
      />
      <Layer
        id={RFW_FILL_PATTERN_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(patternFillStyle, visible)}
      />
      <Layer
        id={RFW_STROKE_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(strokeStyle, visible)}
      />
      <Layer
        id={RFW_SELECTED_FILL_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(selectedFillStyle, visible)}
      />
      <Layer
        id={RFW_SELECTED_STROKE_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...addVisible(selectedStrokeStyle, visible)}
      />
    </ArcGisLayer>
  );
};

export default RedFlagWarningsLayer;
