import { MenuItem, Tooltip } from '@mui/material';
import useClipboard from 'hooks/useClipboard';
import { useTranslation } from 'react-i18next';
import { parseCoordinate } from 'shared/utils';
import { MapToolsMenuItemProps } from './types';

const CopyPositionMenuItem = (props: MapToolsMenuItemProps): JSX.Element => {
  const { position, onClick, padding } = props;
  const { t } = useTranslation();
  const { copied, copyText, reset } = useClipboard();
  const latLng = `${parseCoordinate(position.lat)}, ${parseCoordinate(
    position.lng
  )}`;

  return (
    <Tooltip
      title={t('common.copied')}
      open={copied}
      placement="top"
      onClose={reset}
      leaveDelay={200}
    >
      <MenuItem
        onClick={() => {
          copyText(latLng);
          // Give the tooltip a little time to show up before closing the context menu.
          setTimeout(onClick, 500);
        }}
        sx={{ padding }}
      >
        {latLng}
      </MenuItem>
    </Tooltip>
  );
};

export default CopyPositionMenuItem;
