export const getFractionDigits = (value: number): number => {
  if (value < 1) {
    return 2;
  }
  if (value < 100) {
    return 1;
  }
  return 0;
};

export const toLocaleStringWithAdaptiveFraction = (
  distanceMiles: number,
  units?: string
): string => {
  const distanceMilesNumberString = distanceMiles.toLocaleString(undefined, {
    maximumFractionDigits: getFractionDigits(distanceMiles)
  });
  const unitString = units ? ` ${units}` : '';
  return `${distanceMilesNumberString} ${unitString}`;
};
