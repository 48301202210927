import { SyntheticEvent, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, CircularProgress } from '@mui/material';

type CameraImageProps = {
  url: string;
  loading?: boolean;
  mobileFullScreen?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden'
  },
  imageContainerMobileFullScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  image169: {
    paddingTop: '56.25%' /* 16:9 aspect ratio */
  },
  image43: {
    paddingTop: '75%' /* 4:3 aspect ratio */
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  imageMobileFullScreen: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    display: 'block'
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const getAspectRatio = (image: HTMLImageElement): number => {
  const w = image.naturalWidth;
  const h = image.naturalHeight;
  if (w > h) {
    return w / h;
  }
  return h / w;
};

export const CameraImage = (props: CameraImageProps): JSX.Element => {
  const { url, loading = false, mobileFullScreen = false } = props;
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const { classes, cx } = useStyles();
  const isAspectRatioSetRef = useRef(false);

  const handleLoad = (event: SyntheticEvent<HTMLImageElement>): void => {
    if (!isAspectRatioSetRef.current) {
      isAspectRatioSetRef.current = true;
      setAspectRatio(getAspectRatio(event.target as HTMLImageElement));
    }
  };

  return (
    <Box
      className={
        mobileFullScreen
          ? classes.imageContainerMobileFullScreen
          : cx(
              classes.imageContainer,
              aspectRatio === 16 / 9 ? classes.image169 : classes.image43
            )
      }
    >
      <img
        alt=""
        src={url}
        className={
          mobileFullScreen ? classes.imageMobileFullScreen : classes.image
        }
        onLoad={handleLoad}
      />
      {loading && (
        <div className={classes.loaderContainer}>
          <CircularProgress size={48} />
        </div>
      )}
    </Box>
  );
};
