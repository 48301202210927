import { useCallback } from 'react';
import { Layer, LayerProps } from 'react-map-gl/maplibre';
import { usePoisState } from 'state/usePoisState';
import { FirePerimeterPoi } from 'shared/types';
import ArcGisLayer from './ArcGisLayer';
import getWatchdutyTileUrl from './getWatchdutyTileUrl';
import VisibilityToggledSource from './VisibilityToggledSource';
import addVisible from '../../../shared/addVisible';
import { FILL_PATTERN_LAYER_MIN_ZOOM } from '../constants';
import { MapLayerProps } from '../types';
import { useCacheState } from '../../../state';
import { ActiveFirePerimeterDialogContent } from '../ActiveFirePerimeterDialogContent';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../utils';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';
import { BEFORE_LAYER_ID } from '../styles/constants';

type FirisActiveFirePerimetersProperties = {
  OBJECTID: number;
  date_added: number;
  source?: string;
};

type NifcActiveFirePerimetersProperties = {
  OBJECTID: number;
  IncidentName?: string;
  FeatureCategory?: string;
  MapMethod?: string;
  GISAcres?: number;
  CreateDate: number;
  DateCurrent: number;
  PolygonDateTime?: number;
  GACC?: string;
  UnidID?: string;
  LocalIncidentID?: string;
  IRWINID?: string;
  GeometryID?: string;
  CurrentDateAge?: number;
  IncidentTypeCategory?: string;
};

const firisSrc = import.meta.env.VITE_FIRIS_PERIMETERS_URL as string;
const firisFields = ['OBJECTID', 'date_added', 'source'];

const FIRIS_DATA_STALE_TIME_MS = 60 * 1000;
const NIFC_PERIMETERS_FILL_LAYER_ID = 'perimeters-fill';
const FIRIS_PERIMETERS_FILL_LAYER_ID = 'firis-fill';

const POI_SOURCE_NIFC_DISPLAY_STRING = 'NIFC';
// 'FIRIS' is the default source display string for backwards-compatibility before
// `properties.source` was added in the Intel/FIRIS dataset.
const POI_SOURCE_FIRIS_DISPLAY_STRING_DEFAULT = 'FIRIS';

const ActiveFirePerimetersLayer = (props: MapLayerProps): JSX.Element => {
  const { cacheBusterTs } = useCacheState();
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  const handleClickCommon = useCallback(
    (poi: FirePerimeterPoi) => {
      setSelectedPoi({
        type: 'firePerimeter',
        id: poi.id,
        PoiDialogContent: () => (
          <ActiveFirePerimeterDialogContent activeFirePerimeter={poi} />
        )
      });
    },
    [setSelectedPoi]
  );

  const handleClickNifc = useCallback(
    (
      geoJsonFeatures: MapboxFeature<NifcActiveFirePerimetersProperties>[]
    ): void => {
      const { properties } = geoJsonFeatures[0];

      const activeFirePerimeter: FirePerimeterPoi = {
        id: properties.OBJECTID,
        source: POI_SOURCE_NIFC_DISPLAY_STRING,
        dateCurrent: properties.DateCurrent,
        irwinId: properties.IRWINID
      };
      handleClickCommon(activeFirePerimeter);
    },
    [handleClickCommon]
  );

  const handleClickFiris = useCallback(
    (
      geoJsonFeatures: MapboxFeature<FirisActiveFirePerimetersProperties>[]
    ): void => {
      const { properties } = geoJsonFeatures[0];

      const source = properties.source
        ? properties.source
        : POI_SOURCE_FIRIS_DISPLAY_STRING_DEFAULT;
      const activeFirePerimeter: FirePerimeterPoi = {
        id: properties.OBJECTID,
        source,
        dateCurrent: properties.date_added
      };

      setSelectedPoi({
        type: 'firePerimeter',
        id: properties.OBJECTID,
        PoiDialogContent: () => (
          <ActiveFirePerimeterDialogContent
            activeFirePerimeter={activeFirePerimeter}
          />
        )
      });
    },
    [setSelectedPoi]
  );

  useMapLayerEvents<NifcActiveFirePerimetersProperties>({
    layerId: NIFC_PERIMETERS_FILL_LAYER_ID,
    onClick: handleClickNifc
  });

  useMapLayerEvents<FirisActiveFirePerimetersProperties>({
    layerId: FIRIS_PERIMETERS_FILL_LAYER_ID,
    onClick: handleClickFiris
  });

  const selectedId =
    (selectedPoi?.type === 'firePerimeter' && selectedPoi.id) || '';

  const selectedNifcFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': 'nifc' },
    selectedId
  });

  const selectedNifcStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': 'nifc' },
    selectedId
  });

  const selectedFirisFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    selectedId
  });

  const selectedFirisStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    selectedId
  });

  return (
    <>
      <VisibilityToggledSource
        url={getWatchdutyTileUrl('active_perimeters')}
        volatile
        visible={visible}
      >
        <Layer
          id={NIFC_PERIMETERS_FILL_LAYER_ID}
          beforeId={BEFORE_LAYER_ID}
          {...activeFirePerimetersFillStyle}
        />
        <Layer
          id="perimeters-fill-pattern"
          beforeId={BEFORE_LAYER_ID}
          {...patternPerimetersFillStyleNifc}
        />
        <Layer
          id="perimeters-stroke"
          beforeId={BEFORE_LAYER_ID}
          {...activeFirePerimetersStrokeStyle}
        />
        <Layer {...selectedNifcFillStyle} />
        <Layer {...selectedNifcStrokeStyle} />
      </VisibilityToggledSource>
      <ArcGisLayer
        visible={visible}
        src={firisSrc}
        staleTime={FIRIS_DATA_STALE_TIME_MS}
        cacheBusterTs={cacheBusterTs}
        fields={firisFields}
      >
        <Layer
          id={FIRIS_PERIMETERS_FILL_LAYER_ID}
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(firisPerimetersFillStyle, visible)}
        />
        <Layer
          id="firis-fill-pattern"
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(patternPerimetersFillStyle, visible)}
        />
        <Layer
          id="firis-stroke"
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(firisPerimetersStrokeStyle, visible)}
        />
        <Layer
          id="firis-border"
          beforeId={BEFORE_LAYER_ID}
          {...addVisible(firisPerimetersBorderStyle, visible)}
        />
        <Layer {...selectedFirisFillStyle} />
        <Layer {...selectedFirisStrokeStyle} />
      </ArcGisLayer>
    </>
  );
};

const activeFirePerimetersFillStyle: LayerProps = {
  type: 'fill',
  'source-layer': 'nifc',
  paint: {
    'fill-color': '#FF0000',
    'fill-opacity': 0.25
  }
};

const activeFirePerimetersStrokeStyle: LayerProps = {
  type: 'line',
  'source-layer': 'nifc',
  paint: {
    'line-color': '#FF0000',
    'line-opacity': 0.75,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 18, 3.5]
  }
};

const patternPerimetersFillStyle: LayerProps = {
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  paint: {
    'fill-pattern': 'FirePerimeterPattern',
    'fill-opacity': 0.8
  }
};

const patternPerimetersFillStyleNifc: LayerProps = {
  ...patternPerimetersFillStyle,
  'source-layer': 'nifc'
};

const firisPerimetersFillStyle: LayerProps = {
  type: 'fill',
  paint: {
    'fill-color': '#FD2D2C',
    'fill-opacity': 0.5
  }
};

const firisPerimetersStrokeStyle: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#FD2D2C',
    'line-opacity': 1,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 18, 2.5],
    'line-dasharray': [2, 1]
  }
};

const firisOuterStrokeWidth = 8;

const firisPerimetersBorderStyle: LayerProps = {
  type: 'line',
  layout: {
    'line-cap': 'square'
  },
  paint: {
    'line-color': '#FD2D2C',
    'line-opacity': 0.32,
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12,
      firisOuterStrokeWidth / 12,
      18,
      firisOuterStrokeWidth
    ],
    'line-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12,
      -firisOuterStrokeWidth / 24,
      18,
      -firisOuterStrokeWidth / 2
    ]
  }
};

export default ActiveFirePerimetersLayer;
