import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useMap, Marker, PointLike } from 'react-map-gl/maplibre';
import { Marker as MaplibreMarker } from 'maplibre-gl';
import { LatLng } from 'shared/types';
import distance from '@turf/distance';
import { useMeasureDistanceToolState } from 'state/useMeasureDistanceToolState';
import { range } from 'lodash-es';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MeasureToolPointIcon } from '../Icons';
import { toLocaleStringWithAdaptiveFraction } from './MeasureDistanceToolLayer.util';

// 99999 is the z-index of the map control buttons. See src/css/index.css.
const Z_INDEX = 99999 - 1;

const CIRCLE_STROKE_PIXELS = 3;
const LINE_STROKE_PIXELS = 3;
const MAJOR_TICK_STROKE_PIXELS = 3;
const MAJOR_TICK_HEIGHT_PIXELS = 17;
const MINOR_TICK_STROKE_PIXELS = 2;
const MINOR_TICK_HEIGHT_PIXELS = 10;
const STROKE_STYLE = 'solid black';

const FIRST_MAJOR_TICK_BOUNDS_PIXELS = 150;

type TickStop = {
  majorTickMiles: number;
  minorTicksCount: number;
};

type DisplayMeasurements = {
  radiusMiles: number;
  radiusPixels: number;
  angleRad: number;
  tickIntervalPixels: number;
  majorTickStop: TickStop;
};

type PointMarkerProps = {
  latLng: LatLng;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onDrag: (latLng: LatLng) => void;
};

type TickMarkProps = {
  measurements: DisplayMeasurements;
  isInverted: boolean;
  type: 'major' | 'minor';
  width: number;
  distanceMiles: number;
};

type TicksProps = {
  measurements: DisplayMeasurements;
  minorTicksCount: number;
};

const MAJOR_TICK_INTERVALS_MILES: TickStop[] = [
  {
    majorTickMiles: 0.05,
    minorTicksCount: 5 // Every 0.01 miles
  },
  {
    majorTickMiles: 0.1,
    minorTicksCount: 4 // Every 0.025 miles
  },
  {
    majorTickMiles: 0.25,
    minorTicksCount: 5 // Every 0.05 miles
  },
  {
    majorTickMiles: 0.5,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 1,
    minorTicksCount: 4
  },
  {
    majorTickMiles: 2.5,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 5,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 10,
    minorTicksCount: 4
  },
  {
    majorTickMiles: 25,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 50,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 100,
    minorTicksCount: 4
  },
  {
    majorTickMiles: 250,
    minorTicksCount: 5
  },
  // Note: the following tick stops are not likely to be encountered in practice.
  {
    majorTickMiles: 500,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 1000,
    minorTicksCount: 4
  },
  {
    majorTickMiles: 2500,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 5000,
    minorTicksCount: 5
  },
  {
    majorTickMiles: 10000,
    minorTicksCount: 4
  }
];

const DEFAULT_DISPLAY_MEASUREMENTS: DisplayMeasurements = {
  radiusMiles: 0,
  radiusPixels: 0,
  angleRad: 0,
  tickIntervalPixels: 0,
  majorTickStop: MAJOR_TICK_INTERVALS_MILES[0]
};

const PointMarker = forwardRef(function PointMarker(
  props: PointMarkerProps,
  ref: ForwardedRef<MaplibreMarker>
): JSX.Element {
  const { latLng, onDrag, onDragEnd, onDragStart } = props;
  const { t } = useTranslation();

  // This CSS increases the tap target size of the marker without changing
  // how it is displayed or layed out.
  const increaseTargetSizeStyle: CSSProperties = {
    position: 'relative',
    padding: '22px',
    margin: '-22px'
  };
  const pointStyle: CSSProperties = {
    ...increaseTargetSizeStyle,
    WebkitTouchCallout: 'none'
  };
  return (
    <Marker
      ref={ref}
      latitude={latLng.lat}
      longitude={latLng.lng}
      draggable
      onDragStart={(e) => onDragStart && onDragStart()}
      onDrag={(e) => onDrag(e.lngLat)}
      onDragEnd={(e) => onDragEnd && onDragEnd()}
      style={{ zIndex: Z_INDEX, lineHeight: '1px', cursor: 'pointer' }}
    >
      <Tooltip
        title={t('map.layers.measureDistanceTool.dragToMove')}
        placement="top"
      >
        <div style={pointStyle}>
          <img
            src={MeasureToolPointIcon.data}
            width={MeasureToolPointIcon.width}
            height={MeasureToolPointIcon.height}
            alt="Measure Tool Point"
          />
        </div>
      </Tooltip>
    </Marker>
  );
});

const TickMark = (props: TickMarkProps): JSX.Element => {
  const { measurements, isInverted, type, width, distanceMiles } = props;
  const strokePixels =
    type === 'major' ? MAJOR_TICK_STROKE_PIXELS : MINOR_TICK_STROKE_PIXELS;
  const heightPixels =
    type === 'major' ? MAJOR_TICK_HEIGHT_PIXELS : MINOR_TICK_HEIGHT_PIXELS;

  const commonStyle: CSSProperties = {
    position: 'relative',
    width: `${width}px`,
    height: `${heightPixels}px`,
    borderLeft: `${strokePixels}px ${STROKE_STYLE}`,
    verticalAlign: 'top',
    display: 'inline-block'
  };

  const invertedStyle: CSSProperties = {
    ...commonStyle,
    top: `${measurements.radiusPixels - heightPixels}px`
  };
  const normalStyle: CSSProperties = {
    ...commonStyle,
    height: `${heightPixels - LINE_STROKE_PIXELS}px`
  };

  const normalLabelStyle: CSSProperties = {
    position: 'relative',
    top: `${heightPixels}px`,
    left: 0,
    right: 0,
    marginLeft: '-1.5em',
    width: '5em',
    textShadow:
      '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'
  };
  const invertedLabelStyle: CSSProperties = {
    ...normalLabelStyle,
    top: `-${heightPixels + 5}px`,
    transform: 'rotate(180deg)',
    marginLeft: '-2em',
    textAlign: 'right',
    textShadow:
      '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'
  };

  const label = toLocaleStringWithAdaptiveFraction(distanceMiles, 'mi');
  return (
    <div style={isInverted ? invertedStyle : normalStyle}>
      {label && type === 'major' && (
        <Typography
          variant="body2"
          style={isInverted ? invertedLabelStyle : normalLabelStyle}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

const Ticks = (props: TicksProps): JSX.Element => {
  const { measurements, minorTicksCount } = props;
  const count = Math.floor(
    (measurements.radiusPixels / measurements.tickIntervalPixels) *
      measurements.majorTickStop.minorTicksCount
  );

  const commonStyle: CSSProperties = {
    position: 'relative',
    left: '50%',
    width: `${measurements.radiusPixels}px`,
    height: `${measurements.radiusPixels}px`,
    overflow: 'clip',
    whiteSpace: 'nowrap'
  };
  const invertedStyle: CSSProperties = {
    ...commonStyle,
    borderBottom: `${LINE_STROKE_PIXELS}px ${STROKE_STYLE}`
  };
  const normalStyle: CSSProperties = {
    ...commonStyle,
    top: '50%',
    marginTop: `-${LINE_STROKE_PIXELS / 2}px`,
    borderTop: `${LINE_STROKE_PIXELS}px ${STROKE_STYLE}`
  };

  const isInverted =
    measurements.angleRad > Math.PI / 2 &&
    measurements.angleRad < (3 * Math.PI) / 2;
  return (
    <div style={isInverted ? invertedStyle : normalStyle}>
      {range(0, count + 2).map((i) => (
        <TickMark
          key={i}
          isInverted={isInverted}
          measurements={measurements}
          width={measurements.tickIntervalPixels / minorTicksCount}
          type={i % minorTicksCount === 0 && i > 0 ? 'major' : 'minor'}
          distanceMiles={
            (measurements.majorTickStop.majorTickMiles * i) / minorTicksCount
          }
        />
      ))}
    </div>
  );
};

const DISTANCE_LABEL_OFFSET_X_PIXELS = 40;
const DISTANCE_LABEL_OFFSET_Y_PIXELS = 25;
const DISTANCE_LABEL_OFFSET_TABLE_COUNT = 50;

// Pre-compute an offset lookup table for the distance label for
// multiple points around a circle.
const OFFSET_LOOKUP_TABLE: PointLike[] = range(
  0,
  DISTANCE_LABEL_OFFSET_TABLE_COUNT
).map((i) => {
  const angle = (i / DISTANCE_LABEL_OFFSET_TABLE_COUNT) * Math.PI * 2;
  return [
    Math.floor(Math.cos(angle) * DISTANCE_LABEL_OFFSET_X_PIXELS),
    Math.floor(Math.sin(angle) * DISTANCE_LABEL_OFFSET_Y_PIXELS)
  ];
});

const getOutsideLabelOffset = (angleRad: number): PointLike => {
  const angleNormalized =
    Math.floor(
      (angleRad / (Math.PI * 2) + Math.PI / 64) * OFFSET_LOOKUP_TABLE.length
    ) % OFFSET_LOOKUP_TABLE.length;
  const offset = OFFSET_LOOKUP_TABLE[angleNormalized];
  return offset;
};

export const MeasureDistanceToolLayer = (): JSX.Element | null => {
  const {
    points,
    isToolActive,
    onUpdateStartPointEnd,
    onUpdateStartPointStart,
    setStartPoint,
    setEndPoint
  } = useMeasureDistanceToolState();
  const [displayMeasurements, setDisplayMeasurements] =
    useState<DisplayMeasurements>(DEFAULT_DISPLAY_MEASUREMENTS);
  const startRef = useRef<MaplibreMarker | null>();
  const endRef = useRef<MaplibreMarker | null>();
  const { current: mapRef } = useMap();

  const handleUpdateDisplayMeasurements = useCallback(() => {
    if (!mapRef || !startRef.current || !endRef.current) return;
    const map = mapRef.getMap();
    const start = startRef.current.getLngLat();
    const end = endRef.current.getLngLat();

    const startPointPixels = map.project(start);
    const endPointPixels = map.project(end);
    const x = startPointPixels.x - endPointPixels.x;
    const y = startPointPixels.y - endPointPixels.y;

    const radiusPixels = Math.sqrt(x * x + y * y);
    const angleRad = Math.atan2(y, x) + Math.PI;

    const distanceMiles = distance([start.lng, start.lat], [end.lng, end.lat], {
      units: 'miles'
    });

    const pixelsPerMile = radiusPixels / distanceMiles;
    // Always put a major tick in the first N pixels from the center.
    const majorTickStop =
      MAJOR_TICK_INTERVALS_MILES.findLast(
        (stop) =>
          stop.majorTickMiles * pixelsPerMile < FIRST_MAJOR_TICK_BOUNDS_PIXELS
      ) || MAJOR_TICK_INTERVALS_MILES[0];
    const tickIntervalPixels = pixelsPerMile * majorTickStop.majorTickMiles;

    setDisplayMeasurements({
      radiusMiles: distanceMiles,
      radiusPixels,
      angleRad,
      tickIntervalPixels,
      majorTickStop
    });
  }, [mapRef, startRef, endRef]);

  useEffect(() => {
    if (!isToolActive) return () => {};
    mapRef?.on('move', handleUpdateDisplayMeasurements);
    handleUpdateDisplayMeasurements();
    return () => {
      mapRef?.off('move', handleUpdateDisplayMeasurements);
      setDisplayMeasurements(DEFAULT_DISPLAY_MEASUREMENTS);
    };
  }, [isToolActive, handleUpdateDisplayMeasurements, mapRef]);

  const handleSetEndLatLng = useCallback(
    (latLng: LatLng) => {
      setEndPoint(latLng);
      handleUpdateDisplayMeasurements();
    },
    [setEndPoint, handleUpdateDisplayMeasurements]
  );

  if (!isToolActive || !points) {
    return null;
  }

  const distanceString = toLocaleStringWithAdaptiveFraction(
    displayMeasurements.radiusMiles,
    'mi'
  );
  const labelOffset = getOutsideLabelOffset(displayMeasurements.angleRad);
  const ticksContainerStyle = {
    width: `${displayMeasurements.radiusPixels * 2 + CIRCLE_STROKE_PIXELS}px`,
    height: `${displayMeasurements.radiusPixels * 2 + CIRCLE_STROKE_PIXELS}px`,
    border: `${CIRCLE_STROKE_PIXELS}px ${STROKE_STYLE}`,
    borderRadius: '50%',
    transform: `rotate(${displayMeasurements.angleRad}rad)`
  };
  return (
    <>
      <PointMarker
        ref={(r) => {
          startRef.current = r;
        }}
        latLng={points.start}
        onDragStart={onUpdateStartPointStart}
        onDragEnd={() => {
          onUpdateStartPointEnd();
          handleUpdateDisplayMeasurements();
        }}
        onDrag={setStartPoint}
      />
      <PointMarker
        ref={(r) => {
          endRef.current = r;
        }}
        latLng={points.end}
        onDrag={handleSetEndLatLng}
      />
      <Marker
        latitude={points.start.lat}
        longitude={points.start.lng}
        style={{ zIndex: Z_INDEX - 1 }}
      >
        <div style={ticksContainerStyle}>
          <Ticks
            measurements={displayMeasurements}
            minorTicksCount={displayMeasurements.majorTickStop.minorTicksCount}
          />
        </div>
      </Marker>
      <Marker
        latitude={points.end.lat}
        longitude={points.end.lng}
        offset={labelOffset}
        style={{ zIndex: Z_INDEX }}
      >
        <Typography
          variant="body1"
          style={{
            color: 'black',
            fontWeight: 900,
            textShadow:
              '-1.5px -1.5px 0 white, 1.5px -1.5px 0 white, -1.5px 1.5px 0 white, 1.5px 1.5px 0 white'
          }}
        >
          {distanceString}
        </Typography>
      </Marker>
    </>
  );
};
