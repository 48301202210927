import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import GeoEventReportsMap from 'components/GeoEventReportsMap';
import { StructuredEvacuationsEditLayer } from 'components/Map/layers/StructuredEvacuationsLayer';
import {
  EvacZoneStatus,
  FormEvacZoneStatus,
  GeoEvent,
  LayerEvacZone,
  LayerZoneFeature
} from 'shared/types';
import { useGeoEventEvacZones } from 'hooks/useGeoEventEvacZones';
import { useFormGeoEvent } from './useFormGeoEvent';

type ReportMapProps = {
  geoEvent: GeoEvent;
  zoomControlEnabled: boolean;
  cacheBuster: number;
};

export const ReportMap = (props: ReportMapProps): JSX.Element => {
  const { geoEvent, zoomControlEnabled, cacheBuster } = props;
  const { control, setValue } = useFormContext();

  const mapGeoEvent = useFormGeoEvent({ geoEvent });

  const { geoEventEvacZones: availableZones } = useGeoEventEvacZones(geoEvent);

  const activeEvacuations = useWatch({
    control,
    name: 'activeEvacuations',
    defaultValue: false
  });
  const evacZoneStatuses: FormEvacZoneStatus[] = useWatch({
    control,
    name: 'evacZoneStatuses',
    defaultValue: []
  });

  const handleEvacZoneClick = useCallback(
    (uid: LayerZoneFeature, nextStatus?: EvacZoneStatus) => {
      if (typeof uid !== 'string' || !geoEvent) return;

      const evacZone = availableZones.find((zone) => zone.uidV2 === uid);

      if (!evacZone) return;

      const filteredCurrentZones = evacZoneStatuses.filter(
        (eZS) => eZS.evacZone.uidV2 !== uid
      );

      const geoEventEvacZone = geoEvent.evacZoneStatuses.find(
        (eZS) => eZS.evacZone.uidV2 === uid
      );

      if (nextStatus) {
        // Add
        const updatedZone: FormEvacZoneStatus = {
          evacZone: {
            ...evacZone,
            regionEvacZoneStyle: evacZone.region.evacZoneStyle
          },
          status: nextStatus
        };
        if (geoEventEvacZone) {
          updatedZone.prevStatus = geoEventEvacZone.status;
        }
        setValue('evacZoneStatuses', [...filteredCurrentZones, updatedZone]);
        return;
      }
      // Remove
      setValue('evacZoneStatuses', filteredCurrentZones);
    },
    [availableZones, evacZoneStatuses, geoEvent, setValue]
  );

  const evacZones: LayerEvacZone[] = useMemo(() => {
    const filteredZones = availableZones.filter(
      (zone) => !evacZoneStatuses.find((eZS) => eZS.evacZone.id === zone.id)
    );
    return [
      ...filteredZones.map((zone) => ({
        uidV2: zone.uidV2,
        style: zone.region.evacZoneStyle
      })),
      ...evacZoneStatuses.map((eZS) => ({
        uidV2: eZS.evacZone.uidV2,
        status: eZS.status,
        style: eZS.evacZone.regionEvacZoneStyle
      }))
    ];
  }, [availableZones, evacZoneStatuses]);

  return (
    <GeoEventReportsMap
      geoEvent={mapGeoEvent}
      hiddenMapPixels={null}
      noControls
      layersControlEnabled
      zoomControlEnabled={zoomControlEnabled}
    >
      <StructuredEvacuationsEditLayer
        visible={evacZones.length > 0 && activeEvacuations}
        evacZones={evacZones}
        onClick={handleEvacZoneClick}
        cacheBuster={cacheBuster}
        mode="edit"
      />
    </GeoEventReportsMap>
  );
};
