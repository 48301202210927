import { ReactNode } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import {
  NativeSettings,
  IOSSettings,
  AndroidSettings
} from 'capacitor-native-settings';
import { useHistory } from 'react-router-dom';
import TurnOnNotificationsIcon from 'assets/turn_on_notifications.svg?react';
import BlackButton from 'components/BlackButton';

type EmptyNotificationsProps = {
  disabled?: boolean;
  onboarding?: boolean;
};

type ContainerProps = {
  children: ReactNode;
  onboarding: boolean;
};

type MainContentContainerProps = ContainerProps;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(6, 2, 2),
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: '70vmin',
    height: 'auto',
    maxWidth: 266
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium
  },
  whiteBackground: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  }
}));

const Container = (props: ContainerProps): JSX.Element => {
  const { children, onboarding } = props;
  const { classes } = useStyles();

  if (onboarding) {
    return (
      <Grid
        item
        container
        spacing={2}
        className={classes.container}
        sx={{
          marginLeft: 0,
          marginRight: -2,
          paddingLeft: '8px !important',
          paddingRight: '8px !important'
        }}
      >
        {children}
      </Grid>
    );
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Box>
  );
};

const MainContentContainer = (
  props: MainContentContainerProps
): JSX.Element => {
  const { children, onboarding } = props;
  const { classes } = useStyles();

  if (onboarding) {
    return (
      <Grid item container spacing={2} className={classes.whiteBackground}>
        {children}
      </Grid>
    );
  }

  return <>{children}</>;
};

const EmptyNotifications = (props: EmptyNotificationsProps): JSX.Element => {
  const { disabled = false, onboarding = false } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleTurnOnNotifications = (): void => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App
    });
  };

  const handleAddCounties = (): void => {
    history.push('/notifications/add_counties');
  };

  return (
    <Container onboarding={onboarding}>
      <Grid item xs={12} sx={{ paddingLeft: '0px !important' }}>
        <Box
          className={classes.imageContainer}
          sx={{ marginTop: onboarding ? 2 : undefined }}
        >
          <TurnOnNotificationsIcon className={classes.image} />
        </Box>
      </Grid>

      <MainContentContainer onboarding={onboarding}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            {disabled
              ? t('notifications.turnOn')
              : t('notifications.addCounties')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {t('notifications.neverMiss')}&nbsp;
            <Typography
              component="span"
              className={disabled ? classes.boldText : undefined}
            >
              {disabled
                ? t('notifications.enabling')
                : t('notifications.adding')}
            </Typography>
          </Typography>
        </Grid>

        {disabled && (
          <Grid item xs={12}>
            <Typography>{t('notifications.countiesNotifications')}</Typography>
          </Grid>
        )}
      </MainContentContainer>

      <Grid
        item
        xs={12}
        sx={{
          paddingLeft: onboarding ? '8px !important' : undefined,
          paddingRight: onboarding ? 1 : undefined
        }}
      >
        {onboarding ? (
          <BlackButton
            fullWidth
            onClick={disabled ? handleTurnOnNotifications : handleAddCounties}
          >
            {disabled
              ? t('notifications.turnOn')
              : t('notifications.addCounty')}
          </BlackButton>
        ) : (
          <Button
            fullWidth
            className={classes.button}
            onClick={disabled ? handleTurnOnNotifications : handleAddCounties}
          >
            {disabled
              ? t('notifications.turnOn')
              : t('notifications.addCounty')}
          </Button>
        )}
      </Grid>
    </Container>
  );
};

export default EmptyNotifications;
