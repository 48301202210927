import { Children, ReactNode, isValidElement } from 'react';
import {
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  Card,
  CardActionArea,
  Collapse,
  Chip
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { MembershipPlan } from 'shared/types';
import { MEMBERSHIP_PLANS } from '../../../constants';

type ContentProps = {
  children: ReactNode;
};

type RadioCardProps = ContentProps & {
  title: string;
  value: MembershipPlan | 'donate';
  onChange: (value: MembershipPlan | 'donate') => void;
  checked: boolean;
  active?: boolean;
};

const useStyles = makeStyles<{ checked?: boolean } | void>()(
  (theme, { checked } = {}) => ({
    card: {
      width: '100%',
      borderRadius: 16,
      ...(checked && {
        border: `3px solid ${theme.palette.primary.main}`
      })
    },
    disabledActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0
      }
    }
  })
);

const RadioCard = (props: RadioCardProps): JSX.Element => {
  const { title, value, onChange, checked, children, active = false } = props;
  const { classes } = useStyles({ checked });
  const { t } = useTranslation();

  const Content = Children.toArray(children).find(
    (child) => isValidElement(child) && child.type === RadioCard.Content
  );
  const CollapseContent = Children.toArray(children).find(
    (child) => isValidElement(child) && child.type === RadioCard.CollapseContent
  );

  const isOpen = checked || active;

  return (
    <FormControlLabel
      aria-label={title}
      value={value}
      control={
        <Card elevation={2} className={classes.card}>
          <CardActionArea
            disabled={isOpen}
            className={isOpen ? classes.disabledActionArea : undefined}
          >
            <Stack
              direction="row"
              spacing={1.25}
              sx={{ p: 3, pb: isOpen ? 1 : 3 }}
            >
              <Radio
                sx={{ p: 0, pt: '2px', alignItems: 'flex-start' }}
                disableRipple
                checked={checked}
                icon={active ? <TaskAltIcon color="primary" /> : undefined}
              />

              <Stack spacing={0.5} sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Typography variant="h2" fontSize={24}>
                    {title}
                  </Typography>
                  {!active && value === MEMBERSHIP_PLANS.yearly && (
                    <Chip
                      label={t('supportUs.membership.chip')}
                      color="primary"
                      component="span"
                      variant="watchduty"
                      sx={{ '& span': { fontSize: 13 } }}
                    />
                  )}
                </Stack>

                {Content}
              </Stack>
            </Stack>
          </CardActionArea>

          {CollapseContent && (
            <Collapse in={isOpen}>
              <Stack spacing={2} sx={{ p: 3, pt: 0 }}>
                {CollapseContent}
              </Stack>
            </Collapse>
          )}
        </Card>
      }
      label={null}
      disabled={isOpen}
      onClick={() => {
        if (isOpen) return;
        onChange(value);
      }}
    />
  );
};

RadioCard.Content = function Content({ children }: ContentProps): JSX.Element {
  return <>{children}</>;
};

RadioCard.CollapseContent = function CollapseContent({
  children
}: ContentProps): JSX.Element {
  return <>{children}</>;
};

export default RadioCard;
