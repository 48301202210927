import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import InboxMessagePopup from 'components/InboxMessagePopup';
import usePopupMessage from 'hooks/usePopupMessage';
import NetworkStatusAlert from 'components/NetworkStatusAlert';
import { useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import IncidentsMap from 'components/IncidentsMap';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import {
  MapEntityDrawer,
  DrawerRefContent
} from 'components/Map/MapEntityDrawer';
import { CameraDrawerDetails } from 'components/CameraDetails';
import GeoEventDetails from '../../WildfireGeoEvent/GeoEventCard/GeoEventDetails';
import LocationDetails from '../../Location/LocationContent/LocationDetails';

type ContentProps = {
  initialLat?: number;
  initialLng?: number;
  initialZoom?: number;
};

const HomeContent = ({
  initialLat,
  initialLng,
  initialZoom
}: ContentProps): JSX.Element => {
  const { t } = useTranslation();
  const { message, setMessageAsRead } = usePopupMessage();
  const drawerRef = useRef<DrawerRefContent | null>(null);
  const { mapDrawerEntity, setMapDrawerEntity } = useMapDrawerEntityState();

  return (
    <>
      <Helmet>
        {Capacitor.isNativePlatform() ? (
          <title>{t('home.title')}</title>
        ) : (
          <title>{t('home.webAppTitle')}</title>
        )}
        <meta property="og:title" content="Watch Duty Wildfire Map" />
        <meta
          property="og:description"
          content="Wildfire map and tracker with reports, wind, satellites, and more"
        />
      </Helmet>

      <IncidentsMap
        drawerRef={drawerRef}
        initialLat={initialLat}
        initialLng={initialLng}
        initialZoom={initialZoom}
      />

      <Switch>
        <Route
          exact
          path="/"
          // Keep <MapEntityDrawer /> mounted so the mobile drawer slides open\
          // rather than popping open
          render={() => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={[0.207, 0.47, 0.8]}
              defaultSnapPointPct={0.47}
              onClose={() => {
                if (mapDrawerEntity) {
                  setMapDrawerEntity(null);
                }
              }}
              open={!!mapDrawerEntity}
            >
              {mapDrawerEntity && <mapDrawerEntity.Content />}
            </MapEntityDrawer>
          )}
        />
        <Route
          exact
          path="/i/:geoEventId"
          render={() => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={[0.207, 0.47, 0.8]}
              defaultSnapPointPct={0.47}
              open
            >
              <GeoEventDetails />
            </MapEntityDrawer>
          )}
        />
        <Route
          exact
          path="/location/:locationId"
          render={() => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={[0.207, 0.47, 0.8]}
              defaultSnapPointPct={0.47}
              open
            >
              <LocationDetails />
            </MapEntityDrawer>
          )}
        />
        <Route
          path="/camera/:cameraId"
          render={() => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={[0.1, 0.4, 0.7, 0.8]}
              defaultSnapPointPct={0.7}
              open
            >
              <CameraDrawerDetails />
            </MapEntityDrawer>
          )}
        />
      </Switch>

      <NetworkStatusAlert />

      <InboxMessagePopup
        open={!!message}
        onClose={setMessageAsRead}
        message={message}
      />
    </>
  );
};

export default HomeContent;
