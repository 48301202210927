import {
  includesFullWord,
  formatFileUploadKey,
  getCustomEvacDescriptionFlags,
  getTimePass,
  parseNumericString,
  removeHTMLTags
} from 'shared/utils';

import { TFunction } from 'i18next';
import { isSupportedEmbedUrl } from 'shared/embedHelpers';
import { GeoEvent, NotificationType } from 'shared/types';
import * as Yup from 'yup';
import {
  FormValues,
  ServerFormValues,
  Status,
  ValidationInput,
  ValidationRule
} from './types';

/**
 * Get values from form to post to our server
 *
 * @param {*} values
 * @returns {..values}
 */
export const getValuesForServer = (
  values: Omit<FormValues, 'activeEvacuations'>
): ServerFormValues => {
  const {
    media,
    fileType,
    lat,
    lng,
    az,
    status,
    asset,
    messageHtml,
    messageText,
    ...rest
  } = values;

  const isActive = status === 'active';

  if (!media) {
    return { ...rest, message: messageHtml, isActive, media: [] };
  }

  const mediaUrl = { url: formatFileUploadKey(fileType) };

  return {
    ...rest,
    message: messageHtml,
    isActive,
    // this is where we will add lat/lng and az for the media object
    // instead of the report
    media: [
      {
        lat: lat && lat !== '' ? lat : null,
        lng: lng && lng !== '' ? lng : null,
        az: az ?? null,
        ...mediaUrl
      }
    ]
  };
};

export const hasEvacInfoChanged = (
  prev: string | null,
  next: string
): boolean => {
  const prevValue = (prev || '').trim();
  const nextValue = next.trim();
  return prevValue !== nextValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRedundancyMessage = (errorResponse: any): string => {
  const { data: errorData } = errorResponse;

  if (errorData.length === 1) {
    const recentReport = errorData[0];
    const creator = recentReport.userCreated?.username || 'Someone';
    const timeSince = getTimePass(recentReport.dateCreated);
    const plainTextMessage = removeHTMLTags(recentReport.message);

    return `${creator} just posted ${timeSince}: \n"${plainTextMessage}"\n\nAre you sure you still want to post this report?`;
  }

  return 'Too many reports in the last few minutes for this incident!\n\nAre you sure you still want to post this report?';
};

export const statusToTransKey: Record<Status, string> = {
  inactive: 'common.inactive',
  active: 'common.active'
};

export const getFormValidationSchema = (
  t: TFunction,
  hasEvacZones: boolean
): Yup.SchemaOf<object> =>
  Yup.object().shape(
    {
      media: Yup.mixed<File | Blob | null>(),
      fileType: Yup.string().nullable(),
      embedUrl: Yup.string()
        .url(t('addIncidentReport.inputs.embedUrl.invalidError'))
        .test(
          'is-supported-embed-url',
          t('addIncidentReport.inputs.embedUrl.unsupportedError'),
          (value) => (value ? isSupportedEmbedUrl(value) : true)
        ),
      geoEventId: Yup.number()
        .nullable()
        .required(t('addIncidentReport.inputs.incident.required')),
      messageHtml: Yup.string()
        .required(t('addIncidentReport.inputs.description.required'))
        .min(3, t('addIncidentReport.inputs.description.minError')),
      notificationType: Yup.string().required(
        t('addIncidentReport.inputs.notificationType.required')
      ),
      lat: Yup.number()
        .typeError(t('addIncidentReport.inputs.latitude.typeError'))
        .min(-90, t('addIncidentReport.inputs.latitude.minError'))
        .max(90, t('addIncidentReport.inputs.latitude.maxError'))
        .when('lng', {
          is: (lng?: string) => !!lng,
          then: Yup.number()
            .transform(parseNumericString)
            .nullable()
            .required(t('addIncidentReport.inputs.latitude.required')),
          otherwise: Yup.number().transform(parseNumericString).nullable()
        }),
      lng: Yup.number()
        .typeError(t('addIncidentReport.inputs.longitude.typeError'))
        .min(-180, t('addIncidentReport.inputs.longitude.minError'))
        .max(180, t('addIncidentReport.inputs.longitude.maxError'))
        .when('lat', {
          is: (lat?: string) => !!lat,
          then: Yup.number()
            .transform(parseNumericString)
            .nullable()
            .required(t('addIncidentReport.inputs.longitude.required')),
          otherwise: Yup.number().transform(parseNumericString).nullable()
        }),
      az: Yup.number()
        .typeError(t('addIncidentReport.inputs.azimuth.typeError'))
        .min(0, t('addIncidentReport.inputs.azimuth.minError'))
        .max(360, t('addIncidentReport.inputs.azimuth.maxError')),
      acreage: Yup.number()
        .transform((value) => (isNaN(value) ? null : value))
        .typeError(t('addIncidentReport.inputs.acreage.required'))
        .min(0, t('addIncidentReport.inputs.acreage.invalidError'))
        .max(9999999, t('addIncidentReport.inputs.acreage.invalidError'))
        .nullable(),
      containment: Yup.number()
        .transform((value) => (isNaN(value) ? null : value))
        .typeError(t('addIncidentReport.inputs.containment.required'))
        .min(0, t('addIncidentReport.inputs.containment.invalidError'))
        .max(100, t('addIncidentReport.inputs.containment.invalidError'))
        .integer()
        .nullable(),
      status: Yup.string().required(
        t('addIncidentReport.inputs.status.required')
      ),
      evacuationOrders: Yup.string().when('customOrders', {
        is: (customOrders: boolean) => hasEvacZones && !!customOrders,
        then: Yup.string().required(
          t('geoEventEvacuations.inputs.evacuationOrders.required')
        ),
        otherwise: Yup.string().nullable()
      }),
      evacuationWarnings: Yup.string().when('customWarnings', {
        is: (customWarnings: boolean) => hasEvacZones && !!customWarnings,
        then: Yup.string().required(
          t('geoEventEvacuations.inputs.evacuationWarnings.required')
        ),
        otherwise: Yup.string().nullable()
      }),
      evacuationAdvisories: Yup.string().when('customAdvisories', {
        is: (customAdvisories: boolean) => hasEvacZones && !!customAdvisories,
        then: Yup.string().required(
          t('geoEventEvacuations.inputs.evacuationAdvisory.required')
        ),
        otherwise: Yup.string().nullable()
      }),
      evacuationNotes: Yup.string().nullable(),
      isFps: Yup.boolean(),
      asset: Yup.string().nullable(),
      activeEvacuations: Yup.boolean(),
      evacZoneStatuses: Yup.array(),
      confirmSelectedZones: Yup.boolean().when('confirmSelectedZones', {
        is: (exist: undefined | boolean) => {
          return typeof exist === 'boolean';
        },
        then: Yup.boolean().oneOf([true]),
        otherwise: Yup.boolean()
      }),
      customOrders: Yup.boolean(),
      customWarnings: Yup.boolean(),
      customAdvisories: Yup.boolean(),
      reporterOnlyNotes: Yup.string().nullable()
    },
    [
      ['lat', 'lng'],
      ['confirmSelectedZones', 'confirmSelectedZones']
    ]
  );

export const getInitialFormValues = (geoEvent: GeoEvent): FormValues => {
  const customEvacDescriptionFlags = getCustomEvacDescriptionFlags(geoEvent);

  return {
    media: null,
    fileType: '',
    embedUrl: '',
    geoEventId: geoEvent.id,
    messageHtml: '',
    messageText: '',
    notificationType: NotificationType.Normal,
    lat: '',
    lng: '',
    az: 0,
    acreage: geoEvent.data.acreage ?? null,
    containment: geoEvent.data.containment ?? null,
    status: geoEvent.isActive ? 'active' : 'inactive',
    evacuationOrders: geoEvent.data.evacuationOrders || '',
    evacuationWarnings: geoEvent.data.evacuationWarnings || '',
    evacuationNotes: geoEvent.data.evacuationNotes || '',
    evacuationAdvisories: geoEvent.data.evacuationAdvisories || '',
    isFps: !!geoEvent.data.isFps,
    asset: null,
    activeEvacuations:
      !!(
        geoEvent.data.evacuationOrders ||
        geoEvent.data.evacuationWarnings ||
        geoEvent.data.evacuationNotes ||
        geoEvent.data.evacuationAdvisories
      ) || geoEvent.evacZoneStatuses.length > 0,
    evacZoneStatuses: geoEvent.evacZoneStatuses,
    customOrders: customEvacDescriptionFlags.customOrders,
    customWarnings: customEvacDescriptionFlags.customWarnings,
    customAdvisories: customEvacDescriptionFlags.customAdvisories,
    reporterOnlyNotes: geoEvent.data.reporterOnlyNotes || ''
  };
};

const getValidationRules = (t: TFunction): ValidationRule[] => [
  {
    keywords: ['forward progress stopped', 'stopped', 'FPS'],
    condition: ({ fpsChecked }) => !fpsChecked,
    message: t('addIncidentReport.inputs.description.warnings.fps')
  },
  {
    keywords: ['forward progress stopped', 'stopped', 'FPS'],
    condition: ({ isSilent }) => !!isSilent,
    message: t('addIncidentReport.inputs.description.warnings.fpsSilent')
  },
  {
    keywords: [
      'I',
      'me',
      'my',
      'mine',
      'myself',
      'we',
      'us',
      'our',
      'ours',
      'ourselves'
    ],
    condition: () => true,
    message: t(
      'addIncidentReport.inputs.description.warnings.firstPersonPronouns'
    )
  },
  {
    keywords: ['evacuation', 'evac', 'warning', 'order'],
    condition: ({ isSilent }) => !!isSilent,
    message: t('addIncidentReport.inputs.description.warnings.evacuation')
  },
  {
    keywords: [],
    condition: ({ report }) => report.trim().toLowerCase().startsWith('per'),
    message: t('addIncidentReport.inputs.description.warnings.reportStartWith')
  },
  {
    keywords: [],
    condition: ({ report }) => /#[a-zA-Z]\w*/.test(report),
    message: t('addIncidentReport.inputs.description.warnings.socialHashtags')
  },
  {
    keywords: [
      'injury',
      'injuries',
      'fatality',
      'fatalities',
      'rollover',
      'crash',
      'IWI',
      'ambulance',
      'medical',
      'injured',
      'burnover',
      'entrapment'
    ],
    condition: () => true,
    message: t('addIncidentReport.inputs.description.warnings.injuryFatality')
  },
  {
    keywords: [],
    condition: ({ report }) => /\.{3}|…/.test(report),
    message: t('addIncidentReport.inputs.description.warnings.ellipses')
  },
  {
    keywords: ['alert'], // includes 'alert ca', 'alert california'
    condition: () => true,
    message: t('addIncidentReport.inputs.description.warnings.alertCA')
  }
];

export const validateReport = (
  input: {
    t: TFunction;
  } & ValidationInput
): string | null => {
  const { t, report, fpsChecked, isSilent } = input;

  if (!report) return null;

  for (const rule of getValidationRules(t)) {
    if (
      rule.condition({ report, fpsChecked, isSilent }) &&
      (rule.keywords.length === 0 || includesFullWord(report, rule.keywords))
    ) {
      return rule.message;
    }
  }
  return null;
};
