import {
  Slider as MuiSlider,
  SliderOwnProps,
  Stack,
  StackOwnProps,
  Typography
} from '@mui/material';
import { PlayerStatus } from 'hooks/useAlertCameraPlayer';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';
import { getTimePass } from 'shared/utils';

type TimelapseSliderProps = {
  imageTimestamp: Date;
  isLoading: boolean;
  playerStatus: PlayerStatus;
  timelapseFrameNumber: number;
  timelapseFramesLength: number;
  handleSliderChange: SliderOwnProps['onChange'];
  spacing?: StackOwnProps['spacing'];
  dark?: boolean;
};

const Slider = withStyles(MuiSlider, (theme) => ({
  root: {
    height: 8,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  thumb: {
    width: 14,
    height: 14,
    border: '2px solid white',
    '&:before': {
      boxShadow: 'none'
    },
    boxShadow: theme.shadows[1]
  },
  track: {
    height: 6,
    borderRadius: 4
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: theme.palette.grey[400]
  }
}));

export const TimelapseSlider = (props: TimelapseSliderProps): JSX.Element => {
  const {
    imageTimestamp,
    isLoading,
    playerStatus,
    timelapseFrameNumber,
    timelapseFramesLength,
    handleSliderChange,
    spacing,
    dark = false
  } = props;

  const currentFrameTimeString = moment(imageTimestamp).format('MMM D hh:mm a');
  const currentFrameTimeRelativeString = ` • ${getTimePass(
    imageTimestamp.toISOString()
  )}`;

  return (
    <Stack spacing={spacing} width="100%" sx={{ pointerEvents: 'auto' }}>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        paragraph
        marginTop={0.5}
        marginBottom={0.5}
        color={dark ? 'common.white' : 'text.primary'}
      >
        {currentFrameTimeString}
        {currentFrameTimeRelativeString}
      </Typography>

      <Slider
        color="accent"
        size="small"
        value={timelapseFrameNumber}
        onChange={handleSliderChange}
        min={0}
        max={Math.max(timelapseFramesLength - 1, 1)} // max at least 1 so that a value of zero displays correctly
        disabled={isLoading || playerStatus === 'streamingLive'}
        sx={
          dark
            ? {
                '.MuiSlider-rail': {
                  color: 'grey.100',
                  opacity: 0.76 // 2x default opacity
                }
              }
            : undefined
        }
      />
    </Stack>
  );
};
