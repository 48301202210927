import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { GeoEvent } from 'shared/types';
import * as Yup from 'yup';
import { isSupportedEmbedUrl } from 'shared/embedHelpers';
import { parseNumericString, parsePhoneNumberString } from 'shared/utils';
import { AnyObject } from 'yup/lib/types';
import ObjectSchema, { AssertsShape, TypeOfShape } from 'yup/lib/object';
import { PhoneNumberRegex, UrlRegex } from '../../../../constants';

const useValidationSchema = (
  geoEvent: GeoEvent | undefined,
  hasEvacZones: boolean,
  t: TFunction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ObjectSchema<any, AnyObject, TypeOfShape<any>, AssertsShape<any>> => {
  return useMemo(
    () =>
      Yup.object().shape(
        {
          name: Yup.string().required(
            t('createEditGeoEvent.inputs.name.required')
          ),
          address: Yup.string().when('isComplexParent', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required(
              t('createEditGeoEvent.inputs.name.required')
            )
          }),
          regions: Yup.array().when('isComplexParent', {
            is: true,
            then: Yup.array().min(0),
            otherwise: Yup.array().min(
              1,
              t('createEditGeoEvent.inputs.counties.required')
            )
          }),
          status: Yup.string().required(
            t('createEditGeoEvent.inputs.status.required')
          ),
          notificationType: Yup.string().required(
            t('createEditGeoEvent.inputs.notificationType.required')
          ),
          acreage: Yup.number()
            .transform((value) => (isNaN(value) ? null : value))
            .typeError(t('createEditGeoEvent.inputs.acreage.required'))
            .min(0, t('createEditGeoEvent.inputs.acreage.invalidError'))
            .max(9999999, t('createEditGeoEvent.inputs.acreage.invalidError'))
            .nullable(),
          containment: Yup.number()
            .transform((value) => (isNaN(value) ? null : value))
            .typeError(t('createEditGeoEvent.inputs.containment.required'))
            .min(0, t('createEditGeoEvent.inputs.containment.invalidError'))
            .max(100, t('createEditGeoEvent.inputs.containment.invalidError'))
            .integer()
            .nullable(),
          isComplexParent: Yup.boolean(),
          isFps: Yup.boolean(),
          isPrescribed: Yup.boolean(),
          evacuationOrders: Yup.string().when('customOrders', {
            is: (customOrders: boolean) => hasEvacZones && !!customOrders,
            then: Yup.string().required(
              t('geoEventEvacuations.inputs.evacuationOrders.required')
            ),
            otherwise: Yup.string().nullable()
          }),
          evacuationWarnings: Yup.string().when('customWarnings', {
            is: (customWarnings: boolean) => hasEvacZones && !!customWarnings,
            then: Yup.string().required(
              t('geoEventEvacuations.inputs.evacuationWarnings.required')
            ),
            otherwise: Yup.string().nullable()
          }),
          evacuationAdvisories: Yup.string().when('customAdvisories', {
            is: (customAdvisories: boolean) =>
              hasEvacZones && !!customAdvisories,
            then: Yup.string().required(
              t('geoEventEvacuations.inputs.evacuationAdvisory.required')
            ),
            otherwise: Yup.string().nullable()
          }),
          evacuationNotes: Yup.string().nullable(),
          reportNotificationType: Yup.string(),
          reportMessage: Yup.string().when([], {
            is: () => !geoEvent?.id,
            then: Yup.string().required(
              t('createEditGeoEvent.inputs.reportMessage.required')
            ),
            otherwise: Yup.string()
          }),
          lat: Yup.number(),
          lng: Yup.number(),
          prescribedDateStartLocal: Yup.mixed().nullable(),
          activeEvacuations: Yup.boolean(),
          evacZoneStatuses: Yup.array(),
          confirmSelectedZones: Yup.boolean().when('confirmSelectedZones', {
            is: (exist: boolean) => typeof exist === 'boolean',
            then: Yup.boolean().oneOf([true]),
            otherwise: Yup.boolean()
          }),
          customOrders: Yup.boolean(),
          customWarnings: Yup.boolean(),
          customAdvisories: Yup.boolean(),
          asset: Yup.string().nullable(),
          media: Yup.mixed().nullable(),
          fileType: Yup.string().nullable(),
          embedUrl: Yup.string()
            .url(t('addIncidentReport.inputs.embedUrl.invalidError'))
            .test(
              'is-supported-embed-url',
              t('addIncidentReport.inputs.embedUrl.unsupportedError'),
              (value) => (value ? isSupportedEmbedUrl(value) : true)
            ),
          mediaLat: Yup.number()
            .typeError(t('addIncidentReport.inputs.latitude.typeError'))
            .min(-90, t('addIncidentReport.inputs.latitude.minError'))
            .max(90, t('addIncidentReport.inputs.latitude.maxError'))
            .when('mediaLng', {
              is: (mediaLng: string) => !!mediaLng,
              then: Yup.number()
                .transform(parseNumericString)
                .nullable()
                .required(t('addIncidentReport.inputs.latitude.required')),
              otherwise: Yup.number().transform(parseNumericString).nullable()
            }),
          mediaLng: Yup.number()
            .typeError(t('addIncidentReport.inputs.longitude.typeError'))
            .min(-180, t('addIncidentReport.inputs.longitude.minError'))
            .max(180, t('addIncidentReport.inputs.longitude.maxError'))
            .when('mediaLat', {
              is: (mediaLat: string) => !!mediaLat,
              then: Yup.number()
                .transform(parseNumericString)
                .nullable()
                .required(t('addIncidentReport.inputs.longitude.required')),
              otherwise: Yup.number().transform(parseNumericString).nullable()
            }),
          mediaAz: Yup.number()
            .typeError(t('addIncidentReport.inputs.azimuth.typeError'))
            .min(0, t('addIncidentReport.inputs.azimuth.minError'))
            .max(360, t('addIncidentReport.inputs.azimuth.maxError')),
          links: Yup.array(
            Yup.object().shape({
              linkType: Yup.string()
                .required(t('formLinks.inputs.linkType.required'))
                .nullable(),
              label: Yup.string().required(
                t('formLinks.inputs.linkLabel.required')
              ),
              value: Yup.string()
                .when('linkType', (linkType, schema) => {
                  if (linkType === 'email') {
                    return schema.email(
                      t('formLinks.inputs.linkValue.invalid.email')
                    );
                  }
                  if (linkType === 'website') {
                    return schema.matches(
                      UrlRegex,
                      t('formLinks.inputs.linkValue.invalid.url')
                    );
                  }
                  if (linkType === 'phone') {
                    return schema
                      .transform(parsePhoneNumberString)
                      .matches(
                        PhoneNumberRegex,
                        t('formLinks.inputs.linkValue.invalid.phoneNumber')
                      );
                  }
                  return schema;
                })
                .required(t('formLinks.inputs.linkValue.required'))
            })
          ),
          reporterOnlyNotes: Yup.string().nullable(),
          childWildfires: Yup.array().when('isComplexParent', {
            is: true,
            then: Yup.array().min(
              1,
              t('createEditGeoEvent.inputs.complexIncidents.required')
            ),
            otherwise: Yup.array().max(0)
          })
        },
        [
          ['mediaLat', 'mediaLng'],
          ['confirmSelectedZones', 'confirmSelectedZones']
        ]
      ),
    [geoEvent?.id, hasEvacZones, t]
  );
};

export default useValidationSchema;
