import { LayerProps } from 'react-map-gl/maplibre';
import { BEFORE_LAYER_ID, FONT_NAMES } from 'components/Map/styles/constants';
import {
  DataDrivenPropertyValueSpecification,
  FilterSpecification
} from 'maplibre-gl';
import { FILL_PATTERN_LAYER_MIN_ZOOM } from 'components/Map/constants';
import {
  EVAC_ZONES_EDIT_FILL_LAYER_ID,
  ZONES_SOURCE_LAYER,
  EDIT_FILL_LAYER_GREEN_ADVISORIES_ID,
  EDIT_FILL_LAYER_BLUE_ADVISORIES_ID,
  EDIT_FILL_LAYER_WARNINGS_ID,
  EDIT_FILL_LAYER_ORDERS_ID,
  EDIT_FILL_LAYER_REVERSE_ADVISORIES_ID,
  EDIT_FILL_LAYER_REVERSE_ORDERS_ID,
  INACTIVE_ZONES_LAYER_MIN_ZOOM,
  LABEL_LAYER_MIN_ZOOM,
  FEATURE_UID_V2_FIELD_NAME,
  LABELS_SOURCE_LAYER
} from './constants';
import { ORDERS_PATTERN, STROKE_INACTIVE_COLOR } from '../../../../constants';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../../utils';

export const getFillStyleAll = (): LayerProps => {
  return {
    type: 'fill',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'fill-opacity': 0
    },
    beforeId: BEFORE_LAYER_ID
  };
};

export const getUidFillStyle = (
  color: string,
  zoneIds: string[],
  opacity: number = 0.25
): LayerProps => {
  return {
    type: 'fill',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'fill-color': color,
      'fill-opacity': opacity
    },
    filter: getLayerFilter(zoneIds),
    beforeId: BEFORE_LAYER_ID
  };
};

export const getUidPatternStyle = (
  pattern: string,
  zoneIds: string[]
): LayerProps => {
  const id = `layer_pattern_${pattern}`;
  // overriden for higher contrast with the red exclamation marks
  const opacity = pattern === ORDERS_PATTERN ? 1 : 0.75;

  return {
    id,
    type: 'fill',
    minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'fill-pattern': pattern,
      'fill-opacity': opacity
    },
    filter: getLayerFilter(zoneIds),
    beforeId: BEFORE_LAYER_ID
  };
};

export const getUidStrokeStyle = (
  color: string,
  zoneIds: string[],
  minZoom: number
): LayerProps => {
  const id = `layer_stroke_${color}`;
  return {
    id,
    type: 'line',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'line-color': color,
      'line-opacity': getMinZoomOpacity(minZoom),
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        6,
        0.3,
        8,
        1.0,
        18,
        4.0
      ]
    },
    filter: getLayerFilter(zoneIds)
  };
};

export const getStrokeStyleAll = (): LayerProps => {
  const id = `layer_stroke_all`;
  return {
    id,
    type: 'line',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'line-color': '#000000',
      'line-opacity': getMinZoomOpacity(INACTIVE_ZONES_LAYER_MIN_ZOOM),
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        6,
        0.3,
        8,
        1.0,
        18,
        4.0
      ]
    }
  };
};

export const getSelectedZonePatternStyle = (
  selectedZoneId: string
): LayerProps => {
  return getSelectedPatternStyle({
    idKey: FEATURE_UID_V2_FIELD_NAME,
    otherLayerProps: {
      'source-layer': ZONES_SOURCE_LAYER,
      minzoom: INACTIVE_ZONES_LAYER_MIN_ZOOM
    },
    selectedId: selectedZoneId
  });
};

export const getSelectedZoneStrokeStyle = (
  selectedZoneId: string
): LayerProps => {
  return getSelectedStrokeStyle({
    idKey: FEATURE_UID_V2_FIELD_NAME,
    otherLayerProps: {
      'source-layer': ZONES_SOURCE_LAYER,
      minzoom: INACTIVE_ZONES_LAYER_MIN_ZOOM
    },
    selectedId: selectedZoneId
  });
};

const getLayerFilter = (
  zoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean
): FilterSpecification | undefined => {
  if (isAllEvacZoneLayerOn) {
    return ['!=', ['get', FEATURE_UID_V2_FIELD_NAME], ['literal', null]];
  }
  return ['in', ['get', FEATURE_UID_V2_FIELD_NAME], ['literal', zoneUIds]];
};

export const getFillLayerStyle = (
  evacZoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean
): LayerProps => ({
  id: EVAC_ZONES_EDIT_FILL_LAYER_ID,
  type: 'fill',
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-color': ['coalesce', ['feature-state', 'color'], 'transparent'],
    'fill-opacity': 0.25
  },
  filter: getLayerFilter(evacZoneUIds, isAllEvacZoneLayerOn)
});

export const getEvacAdvisoriesGreenLayerStyle = (
  evacZoneUIds: string[]
): LayerProps => ({
  id: EDIT_FILL_LAYER_GREEN_ADVISORIES_ID,
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-pattern': 'EvacAdvisoryGreenPattern',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'pattern'], 'EvacAdvisoryGreenPattern'],
      0.75,
      0
    ]
  },
  filter: getLayerFilter(evacZoneUIds)
});

export const getEvacAdvisoriesBlueLayerStyle = (
  evacZoneUIds: string[]
): LayerProps => ({
  id: EDIT_FILL_LAYER_BLUE_ADVISORIES_ID,
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-pattern': 'EvacAdvisoryBluePattern',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'pattern'], 'EvacAdvisoryBluePattern'],
      0.75,
      0
    ]
  },
  filter: getLayerFilter(evacZoneUIds)
});

export const getEvacWarningsLayerStyle = (
  evacZoneUIds: string[]
): LayerProps => ({
  id: EDIT_FILL_LAYER_WARNINGS_ID,
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-pattern': 'EvacWarningPattern',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'pattern'], 'EvacWarningPattern'],
      1.0, // Purposefully changed from 0.75 to 1.0 for higher contrast
      0
    ]
  },
  filter: getLayerFilter(evacZoneUIds)
});

export const getEvacOrdersLayerStyle = (
  evacZoneUIds: string[]
): LayerProps => ({
  id: EDIT_FILL_LAYER_ORDERS_ID,
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-pattern': 'EvacOrderPattern',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'pattern'], 'EvacOrderPattern'],
      0.75,
      0
    ]
  },
  filter: getLayerFilter(evacZoneUIds)
});

export const getEvacReverseAdvisoriesLayerStyle = (
  evacZoneUIds: string[]
): LayerProps => ({
  id: EDIT_FILL_LAYER_REVERSE_ADVISORIES_ID,
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-pattern': 'EvacReverseAdvisoryPattern',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'pattern'], 'EvacReverseAdvisoryPattern'],
      0.75,
      0
    ]
  },
  filter: getLayerFilter(evacZoneUIds)
});

export const getEvacReverseOrdersLayerStyle = (
  evacZoneUIds: string[]
): LayerProps => ({
  id: EDIT_FILL_LAYER_REVERSE_ORDERS_ID,
  type: 'fill',
  minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-pattern': 'EvacReverseOrderPattern',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'pattern'], 'EvacReverseOrderPattern'],
      0.75,
      0
    ]
  },
  filter: getLayerFilter(evacZoneUIds)
});

// This one doe not use feature state
export const getMinZoomOpacity = (
  minzoom: number
): DataDrivenPropertyValueSpecification<number> => [
  'step',
  ['zoom'],
  // At zoom levels above minzoom:
  0,
  // At this zoom level and below
  minzoom,
  1
];

// This one uses feature state and will be deprecated
export const getPaintPropertyOpacity = (
  minzoom: number
): DataDrivenPropertyValueSpecification<number> => [
  'step',
  ['zoom'],
  // At zoom levels above minzoom:
  [
    'case',
    ['to-boolean', ['feature-state', 'color']], // If the feature has a color state
    0.75, // Set opacity to 0.75 (active evac zones)
    0 // Otherwise, set opacity to 0 (inactive evac zones)
  ],
  minzoom, // At this zoom level and below
  0.75 // Set opacity to 0.75 for all features
];

export const getPerimeterLayerStyle = (
  evacZoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean
): LayerProps => ({
  id: 'zones-perimeter',
  type: 'line',
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'line-color': [
      'coalesce',
      ['feature-state', 'color'],
      STROKE_INACTIVE_COLOR
    ],
    'line-opacity': getPaintPropertyOpacity(INACTIVE_ZONES_LAYER_MIN_ZOOM),
    'line-width': ['interpolate', ['linear'], ['zoom'], 6, 0.3, 8, 1.0, 18, 4.0]
  },
  filter: getLayerFilter(evacZoneUIds, isAllEvacZoneLayerOn)
});

export const getLabelLayerStyleAll = (): LayerProps => ({
  type: 'symbol',
  'source-layer': LABELS_SOURCE_LAYER,
  minzoom: LABEL_LAYER_MIN_ZOOM,
  paint: {
    'text-color': '#000000',
    'text-halo-width': 1.8,
    'text-halo-color': '#ffffff',
    'text-opacity': 1
  },
  layout: {
    'text-field': ['get', 'zone_name'],
    'text-anchor': 'center',
    'text-justify': 'auto',
    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 10, 13, 16],
    'text-font': FONT_NAMES.bold,
    'text-allow-overlap': ['step', ['zoom'], false, 13, true]
  }
});

export const getLabelLayerStyle = (
  evacZoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean
): LayerProps => ({
  id: 'zones-label',
  type: 'symbol',
  'source-layer': LABELS_SOURCE_LAYER,
  minzoom: LABEL_LAYER_MIN_ZOOM,
  paint: {
    'text-color': '#000000',
    'text-halo-width': 1.8,
    'text-halo-color': '#ffffff',
    'text-opacity': 1
  },
  layout: {
    'text-field': ['get', 'zone_name'],
    'text-anchor': 'center',
    'text-justify': 'auto',
    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 10, 13, 16],
    'text-font': FONT_NAMES.bold,
    'text-allow-overlap': ['step', ['zoom'], false, 13, true]
  },
  filter: getLayerFilter(evacZoneUIds, isAllEvacZoneLayerOn)
});
