import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { MapPinDialogContent } from 'components/Map/MapPinDialogContent';
import { usePoisState } from 'state/usePoisState';
import { MapToolsMenuItemProps } from './types';

const DropPinMenuItem = (props: MapToolsMenuItemProps): JSX.Element => {
  const { position, onClick, padding } = props;
  const { setSelectedPoi } = usePoisState();
  const { t } = useTranslation();

  const handleDropPin = useCallback(
    (lngLatLan: number, lngLatLng: number): void => {
      const mapPin = {
        lat: lngLatLan,
        lng: lngLatLng
      };

      setSelectedPoi({
        coordinates: mapPin,
        type: 'mapPin',
        PoiDialogContent: () => <MapPinDialogContent />
      });
    },
    [setSelectedPoi]
  );

  return (
    <MenuItem
      onClick={() => {
        handleDropPin(position.lat, position.lng);
        onClick();
      }}
      sx={{ padding }}
    >
      {t('map.contextMenu.dropPinBtnTitle')}
    </MenuItem>
  );
};

export default DropPinMenuItem;
